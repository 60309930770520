import moment from "moment";
import agent from "../../services/agent";
import {
  clearEnableUserError,
  clearLoginUserError,
  enableUserError,
  enableUserPending,
  enableUserPendingError,
  enableUserPendingSuccess,
  loginUserError,
  loginUserPending,
  loginUserPendingError,
  loginUserPendingSuccess,
  loginUserSuccess,
} from "./login.actions";
import {
  SET_CURRENT_PROFILE,
  SET_FORGOT_PASSWORD_CONFIRMATION_STATUS,
} from "../constants/profile.constants";
import { ProfileState } from "../../types/profile";
import { clearForm } from "./form.actions";
import { showSnackbarStatus } from "./snackbar.actions";
import { setUnitValueSelection } from "./qh-selection.actions";
import { clearAccessStatus } from "./maintenence.actions";

export const setTokenDetails = (authData: any) => {
  const { access_token, expires_in, token_type, id_token, refresh_token } = authData;
  const expires = moment().unix() + expires_in;
  localStorage.setItem('accessToken', access_token);
  localStorage.setItem('expiresInSec', expires);
  localStorage.setItem('token_type', token_type);
  localStorage.setItem('refresh_token', refresh_token);
  localStorage.setItem('id_token', id_token);
};

export const setCurrentProfile = (currentProfile: ProfileState): Action => ({
  type: SET_CURRENT_PROFILE,
  payload: currentProfile,
});

export const setForgotPasswordConfirmationStatus = (status: string): Action => ({
  type: SET_FORGOT_PASSWORD_CONFIRMATION_STATUS,
  payload: status,
});

export const userLogin = (code: string) => (
  dispatch: Function
) => {
  dispatch(loginUserPending());
  dispatch(clearLoginUserError());
  agent.Auth.login(code)
    .then((authData) => {
      setTokenDetails(authData);
      const { id_token, expires_in } = authData;
      agent.Auth.getCurrentUser(id_token)
        .then((currentUser) => {
          dispatch(loginUserPendingSuccess());
          dispatch(clearAccessStatus());
          dispatch(setCurrentProfile(currentUser));
          dispatch(setUnitValueSelection(currentUser.geoLocation === "AMERICAS" ? "us" : ((currentUser.geoLocation === "APAC") || (currentUser.geoLocation === "EMEA")) ? "metric" : "metric"));
          dispatch(
            loginUserSuccess(currentUser.email, id_token, expires_in)
          );
        })
        .catch((err) => {
          dispatch(loginUserPendingError());
          console.log("Get Current User server error", err);
        });
    })
    .catch((err) => {
      dispatch(loginUserPendingError());
      if (err && err.response) {
        const { error } = err.response.body;
        dispatch(loginUserError(error));
      } else {
        console.log("User login server error", err);
      }
    });
};

export const enableUserAccount = (code: string) => (
  dispatch: Function,
  getState: Function
) => {
  const currentState = getState();
  const { formCompleteRegistration } = currentState && currentState.form;

  dispatch(enableUserPending());
  dispatch(clearEnableUserError());
  agent.Auth.enableAccount(formCompleteRegistration, code)
    .then((result) => {
      dispatch(userLogin(result, formCompleteRegistration.password));
      dispatch(enableUserPendingSuccess());
      dispatch(clearForm());
    })
    .catch((err) => {
      dispatch(enableUserPendingError());
      if (err && err.response) {
        const { message } = err.response.body;
        dispatch(enableUserError(message));
        return;
      }
      console.log("enable user server error", err);
      dispatch(enableUserError(null));
      dispatch(clearForm());
      dispatch(
        showSnackbarStatus("Something went wrong. Please try again later")
      );
    });
};

export const forgotPassword = (email: string) => (dispatch: Function) => {
  dispatch(setForgotPasswordConfirmationStatus("INPROGRESS"));
  agent.Auth.forgotPassword(email)
    .then((result) => {
      dispatch(setForgotPasswordConfirmationStatus("EMAIL_SENT"));
      dispatch(clearForm());
    })
    .catch((err) => {
      dispatch(setForgotPasswordConfirmationStatus("INVALID_EMAIL"));
    });
};

export const resetPassword = (code: string) => (
  dispatch: Function,
  getState: Function
) => {
  const currentState = getState();
  const { formResetPassword } = currentState && currentState.form;

  dispatch(clearEnableUserError());
  dispatch(enableUserPending());
  agent.Auth.resetPassword(formResetPassword, code)
    .then((result) => {
      dispatch(userLogin(result, formResetPassword.password));
      dispatch(enableUserPendingSuccess());
      dispatch(clearForm());
    })
    .catch((err) => {
      dispatch(enableUserPendingError());
      if (err && err.response) {
        const { message } = err.response.body;
        dispatch(enableUserError(message));
        return;
      }
      console.log("reset password error", err);
      dispatch(enableUserError(null));
      dispatch(clearForm());
      dispatch(
        showSnackbarStatus("Something went wrong. Please try again later")
      );
    });
};
