export const updateForm = (data: any): Action => ({
  type: "FORM_UPDATE",
  payload: data,
});


export const selectedDutyPoints = (data: any): Action => ({
  type: "SELECTED_DUTYPOINTS",
  payload: data,
});

export const clearForm = (): Action => ({
  type: "FORM_CLEAR",
});

export const setOrificeValue = (data: any): Action => ({
  type: "SET_ORIFICE_VALUE",
  payload: data,
});

