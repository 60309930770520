import { qhDataState } from "../../types/qhData";
import { GET_SEARCH_DATA_ERROR } from "../constants/pumpData.constants";
import {
  SET_QH_DATA_LIST_FILTER,
  SET_ACTIVE_PUMP_FAMILY,
  CLEAR_PUMP_DATA_LIST_FILTER,
  SET_MODIFIED_DATA,
  SET_PUMP_DATA_LIST_FILTER,
  SET_SEARCH_PUMP_DATA,
  SET_INITIAL_STATE,
  SET_UNIT_VALUE_SELECTION,
  SEARCH_DATA_ERROR,
  GET_GRAPH_DATA_SUCCESS,
  SET_GRAPH_ERROR,
  SET_GRAPH_DATA,
  SET_PRINT_DATA,
  GET_PRINT_DATA_SUCCESS,
  SET_QH_SELECTION_FILTERED_PUMP_DATA_IDS,
  GET_SEARCH_DATA_PENDING,
  UPDATE_GRAPH_DATA_PENDING,
  UPDATE_GRAPH_DATA_SUCCESS,
  UPDATE_GRAPH_DATA_ERROR,
  SET_CURRENT_PUMP_DETAILS,
  SET_CURRENT_FIELD_CHANGE,
  SET_PS_GRAPH_DATA,
  SET_PUMP_SUB_TYPE,
  SET_CURRENT_PS_PUMP_DETAILS,
  SET_GRAPHTYPE,
  CLEAR_SEARCH_PUMP_DATA,
  OPEN_SELECTED_PUMPS_MODAL,
  CLOSE_SELECTED_PUMPS_MODAL,
  SET_TOTAL_CURVE,
  SET_PARALLEL_PUMP,
  SET_MENU_OPTIONS,
  SET_NEXT_DISABLE,
  SET_NEXT_DISABLE_FALSE,
  RESET_STORAGE,
  OPEN_HELP_MODEL,
  CLOSE_HELP_MODEL
} from "../constants/qh-selection.constants";

const initialState: qhDataState = {
  // selectedUnit: 'metric',
  filter: {
    // selected_viscosity_filter: 1,
    selected_sorting: {
      label: "Efficiency: High to Low",
      value: "Eff#desc",
    },
    family: [{ label: 'All', value: 'All' }],
    impeller: [{ label: 'All', value: 'All' }],
    impMaterial: [{ label: 'All', value: 'All' }],
    subType: [{ label: 'All', value: 'All' }],
    selected_density_filter: 0,
  },
  pumpDataList: [],
  graphData: {},
  psGraphData: {},
  filteredPumpDataIds: [],
  filteredPumpIndexes: [],
  isPending: false,
  sSelectedPumpsModalOpen: false,
  currentPumpDetails: {},
  currentFieldChange: "",
  pumpSubTypeOptions: [],
  currentPSPumpDetails: [],
  GraphType: '',
  filteredPumpRows: [],
  totalCurve: false,
  parallelPump: 0,
  staticHead: Number(0),
  graphError: null,
  isOpenHelp: false,
};

export default (
  localState: UserState = initialState,
  action: Action
): qhDataState => {
  switch (action.type) {
    case SET_INITIAL_STATE: {
      return {
        ...initialState,
        isTestSelectionModalOpen: true,
        newValue: {
          ...action.payload,
        }
      };
    }
    case SET_QH_DATA_LIST_FILTER: {
      return {
        ...localState,
        filter: {
          ...action.payload,
        },
      };
    }
    case CLEAR_PUMP_DATA_LIST_FILTER: {
      return {
        ...localState,
        filter: {
          ...initialState.filter,
        },
      };
    }
    case SET_MODIFIED_DATA: {
      return {
        ...localState,
        isDataModified: true,
      };
    }
    case SET_PUMP_DATA_LIST_FILTER: {
      return {
        ...localState,
        filter: {
          ...action.payload,
        },
      };
    }
    case SET_ACTIVE_PUMP_FAMILY: {
      return {
        ...localState,
        activePumpFamily: action.payload,
      };
    }
    case SET_SEARCH_PUMP_DATA: {
      const { pumpListOdata } = action.payload;
      return {
        ...localState,
        isPending: false,
        pumpDataList: pumpListOdata && pumpListOdata.value,
        totalRecords: pumpListOdata["@odata.count"],
      };
    }

    case CLEAR_SEARCH_PUMP_DATA: {
      return {
        ...localState,
        isPending: false,
        pumpDataList: [],
        totalRecords: 0,
      };
    }

    case SET_PUMP_SUB_TYPE: {
      return {
        ...localState,
        pumpSubTypeOptions: action.payload,
      }
    }

    case SET_GRAPH_DATA: {
      return {
        ...localState,
        graphData: action.payload,
      };
    }

    case SET_PS_GRAPH_DATA: {
      return {
        ...localState,
        psGraphData: action.payload,
      };
    }
    case SET_GRAPHTYPE: {
      return {
        ...localState,
        GraphType: action.payload,
      }
    }
    case SET_PRINT_DATA: {
      return {
        ...localState,
        printData: action.payload,
      };
    }
    case GET_GRAPH_DATA_SUCCESS: {
      return {
        ...localState,
      };
    }
    case SET_GRAPH_ERROR: {
      return {
        ...localState,
        graphError: action.payload,
      }
    }
    case GET_PRINT_DATA_SUCCESS: {
      return {
        ...localState,
      };
    }
    case SET_UNIT_VALUE_SELECTION: {
      return {
        ...localState,
        selectedUnit: action.payload,
      };
    }
    case SEARCH_DATA_ERROR: {
      const { errorMessage } = action.payload;
      return {
        ...localState,
        errorMessage,
        isPending: false,
      };
    }
    case SET_QH_SELECTION_FILTERED_PUMP_DATA_IDS: {
      const { pumpDataIds, pumpIndexes, selectedRows } = action.payload;
      return {
        ...localState,
        filteredPumpDataIds: pumpDataIds,
        filteredPumpIndexes: pumpIndexes,
        filteredPumpRows: selectedRows,
      };
    }
    case GET_SEARCH_DATA_PENDING: {
      return {
        ...localState,
        isPending: true,
      };
    }
    case GET_SEARCH_DATA_ERROR: {
      return {
        ...localState,
        isPending: false,
      };
    }
    case UPDATE_GRAPH_DATA_PENDING: {
      return {
        ...localState,
        isGraphPending: true,
      };
    }
    case UPDATE_GRAPH_DATA_SUCCESS: {
      return {
        ...localState,
        isGraphPending: false,
      };
    }
    case UPDATE_GRAPH_DATA_ERROR: {
      return {
        ...localState,
        isGraphPending: false,
      };
    }
    case SET_CURRENT_PUMP_DETAILS: {
      return {
        ...localState,
        currentPumpDetails: action.payload
      }
    }
    case SET_CURRENT_PS_PUMP_DETAILS: {
      return {
        ...localState,
        currentPSPumpDetails: action.payload
      }
    }
    case SET_CURRENT_FIELD_CHANGE: {
      return {
        ...localState,
        currentFieldChange: action.payload
      }
    }
    case OPEN_SELECTED_PUMPS_MODAL: {
      return {
        ...localState,
        isSelectedPumpsModalOpen: true,
      };
    }
    case CLOSE_SELECTED_PUMPS_MODAL: {
      return {
        ...localState,
        isSelectedPumpsModalOpen: false,
      };
    }
    case SET_TOTAL_CURVE: {
      return {
        ...localState,
        totalCurve: action.payload
      }
    }
    case SET_PARALLEL_PUMP: {
      return {
        ...localState,
        parallelPump: action.payload
      }
    }
    case SET_MENU_OPTIONS:{
      return {
        ...localState,
        staticHead: action.payload,
      }
    }
    case SET_NEXT_DISABLE:{
      return{
      ...localState,
      isNextDisable: true,
      }
    }
    case SET_NEXT_DISABLE_FALSE:{
      return{
        ...localState,
        isNextDisable: false,
        }
    }
    case RESET_STORAGE: {
      return {
        ...initialState,
      }
    }
    case OPEN_HELP_MODEL: {
      return {
        ...localState,
        isOpenHelp: true,
      }
    }
    case CLOSE_HELP_MODEL: {
      return {
        ...localState,
        isOpenHelp: false,
      }
    }
    default: {
      return localState;
    }
  }
};
