export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_ERROR = "CREATE_USER_ERROR";
export const CREATE_USER_PENDING = "CREATE_USER_PENDING";
export const CLEAR_USER_ERROR = "CLEAR_USER_ERROR";
export const SET_INITIAL_STATE = "SET_INITIAL_STATE";
export const OPEN_CREATE_USER_MODAL = "OPEN_CREATE_USER_MODAL";
export const CLOSE_CREATE_USER_MODAL = "CLOSE_CREATE_USER_MODAL";
export const SET_USERS_PENDING = "SET_USERS_PENDING";
export const SET_USERS_LIST = "SET_USERS_LIST";
export const SET_USER_DETAILS_INITIAL_STATE = "SET_USER_DETAILS_INITIAL_STATE";
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const GET_USER_DETAILS_PENDING = "GET_USER_DETAILS_PENDING";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const SEND_RESET_PASSWORD_PENDING = "SEND_RESET_PASSWORD_PENDING";
export const SEND_RESET_PASSWORD_SUCCESS = "SEND_RESET_PASSWORD_SUCCESS";
export const SEND_RESET_PASSWORD_ERROR = "SEND_RESET_PASSWORD_ERROR";
export const OPEN_CHANGE_PASSWORD_MODAL = "OPEN_CHANGE_PASSWORD_MODAL";
export const CLOSE_CHANGE_PASSWORD_MODAL = "CLOSE_CHANGE_PASSWORD_MODAL";
export const CHANGE_PASSWORD_PENDING = "CHANGE_PASSWORD_PENDING";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";
export const CLEAR_CHANGE_PASSWORD_ERROR = "CLEAR_CHANGE_PASSWORD_ERROR";
export const CLOSE_EDIT_USER_MODAL = "CLOSE_EDIT_USER_MODAL";
export const OPEN_EDIT_USER_MODAL = "OPEN_EDIT_USER_MODAL";
export const UPDATE_USER_PENDING = "UPDATE_USER_PENDING";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";
export const CLEAR_UPDATE_USER_ERROR = "CLEAR_UPDATE_USER_ERROR";
export const SET_ROLES_LIST_ASYNC = "SET_ROLES_LIST_ASYNC";
export const CLEAR_UPDATE_PROFILE_ERROR = "CLEAR_UPDATE_PROFILE_ERROR";
export const UPDATE_PROFILE_PENDING = "UPDATE_PROFILE_PENDING";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_ERROR = "UPDATE_PROFILE_ERROR";
export const CLOSE_EDIT_PROFILE_MODAL = "CLOSE_EDIT_PROFILE_MODAL";
export const OPEN_EDIT_PROFILE_MODAL = "OPEN_EDIT_PROFILE_MODAL";
export const SET_USER_DATA = "SET_USER_DATA";

export const APPLICATION = [
    {
      label: "Industrial",
      value: "Industrial",
    },
    {
      label: "Horticulture",
      value: "Horticulture",
    },
  ];

  export const GEO_LOCATION = [
    {
      label: "EMEA",
      value: "EMEA",
    },
    {
      label: "AMERICAS",
      value: "AMERICAS",
    },
    {
      label: "APAC",
      value: "APAC",
    },
  ];