import superagentPromise from "superagent-promise";
import _superagent from "superagent";
import configureStore from "../store/store";
import { logoutUser } from "../store/actions/actions";

const superagent = superagentPromise(_superagent, Promise);

const responseBody = (res) => res.body;
const apiUrl = window.__ENV && window.__ENV.apiUrl;
// const apiUrl = window.__ENV && window.__ENV.apiUrl;//eslint-disable-next-line
const OAuth_apiUrl = window.__ENV && window.__ENV.OAuth_apiUrl;//eslint-disable-next-line
const grant_type = window.__ENV && window.__ENV.grant_type;//eslint-disable-next-line
const redirect_uri = window.__ENV && window.__ENV.redirect_uri;//eslint-disable-next-line
const client_id = window.__ENV && window.__ENV.client_id;//eslint-disable-next-line
const client_secret = window.__ENV && window.__ENV.client_secret;

const handleErrors = (err) => {
  if (err && err.status === 401) {
    const store = configureStore();
    store.dispatch(logoutUser("Unauthorized/TokenExpired"));
  }
};

const requests = {
  post: (url, body) =>
    superagent
      .post(url, body)
      .set("Content-Type", "application/json")
      .on("error", handleErrors)
      .then(responseBody),
  postData: (url, body) =>
    superagent
      .post(url, body)
      .set("Content-Type", "application/x-www-form-urlencoded")
      .on("error", handleErrors)
      .then(responseBody),
  postWithoutToken: (url) =>
    superagent
      .post(url)
      // .set('Content-Type', 'application/json')
      // .set('Authorization', `Bearer ${token}`)
      .on('error', handleErrors)
      .then(responseBody),
  getWithToken: (url, token) =>
    superagent
      .get(url)
      .set("Content-Type", "application/json")
      .set("Authorization", `Bearer ${token}`)
      .on("error", handleErrors)
      .then(responseBody),
  postWithToken: (url, body, token) =>
    superagent
      .post(url, body)
      .set("Content-Type", "application/json")
      .set("Authorization", `Bearer ${token}`)
      .on("error", handleErrors)
      .then(responseBody),
  deleteWithToken: (url, token) =>
    superagent
      .del(url, token)
      .set("Content-Type", "application/json")
      .set("Authorization", `Bearer ${token}`)
      .on("error", handleErrors)
      .then(responseBody),
  putWithToken: (url, body, token) =>
    superagent
      .put(url, body, token)
      .set("Content-Type", "application/json")
      .set("Authorization", `Bearer ${token}`)
      .on("error", handleErrors)
      .then(responseBody),
  uploadWithToken: (url, data, token) =>
    superagent
      .post(url, data)
      .set("Authorization", `Bearer ${token}`)
      .on("error", handleErrors)
      .then(responseBody),
};

const Auth = {
  login: (code: string) => {
    const formData = { //eslint-disable-next-line
      "grant_type": grant_type,
      "code": code,//eslint-disable-next-line
      "redirect_uri": redirect_uri,//eslint-disable-next-line
      "client_id": client_id,//eslint-disable-next-line
      "client_secret": client_secret,
    };

    return requests.postData(`${OAuth_apiUrl}/token`, formData);
  },

  getRefreshToken: (refreshToken) => {
    console.log("refreshToken: ", refreshToken)
    return requests.postWithoutToken(`${OAuth_apiUrl}/token?grant_type=refresh_token&client_id=${client_id}&client_secret=${client_secret}&refresh_token=${refreshToken}`);
  },

  getCurrentUser: (access_token: string) => {
    return requests.getWithToken(`${apiUrl}/api/user/profile`, access_token);
  },
  enableAccount: (data: any, code) => {
    const { password, confirmPassword } = data;
    return requests.postData(`${apiUrl}/api/user/enableuser`, {
      password,
      confirmPassword,
      code,
    });
  },
  forgotPassword: (email: string) => {
    return requests.postData(`${apiUrl}/api/user/forgotpassword`, {
      email,
    });
  },
  logout: (access_token: string, userId: string, actionType: string) => {
    return requests.getWithToken(`${apiUrl}/api/user/logout?userId=${userId}&actionType=${actionType}`,
      access_token);
  },
  resetPassword: (data: any, code) => {
    const { password, confirmPassword, email } = data;
    return requests.postData(`${apiUrl}/api/user/resetpassword`, {
      password,
      confirmPassword,
      code,
      email,
    });
  },
};

const User = {
  createUser: (access_token: string, user: Object) => {
    const {
      firstName,
      lastName,
      displayName,
      email,
      mobileNumber,
      new_user_role,
      isNotify,
    } = user;
    return requests.postWithToken(
      `${apiUrl}/api/user/create`,
      {
        firstName,
        lastName,
        displayName,
        email,
        mobileNumber,
        notifyUser: isNotify,
        roleId: [new_user_role && new_user_role.value],
        //new_user_role.map(function (item) {
        //  return item["value"];
        //}),
      },
      access_token
    );
  },
  getUserDetails: (access_token: string, userId: any) => {
    return requests.getWithToken(
      `${apiUrl}/odata/GetUserDetails?UserId=${userId}`,
      access_token
    );
  },
  sendResetPasswordEmail: (email: string) => {
    return requests.post(`${apiUrl}/api/user/forgotpassword`, { email });
  },
  changePassword: (access_token: string, data: any) => {
    return requests.postWithToken(
      `${apiUrl}/api/user/changepassword`,
      data,
      access_token
    );
  },
  updateUser: (access_token: string, data: any) => {
    data = {
      ...data,
      // roleId: [data.selected_user_role && data.selected_user_role.value],
      //data.selected_user_role.map(function (item) {
      //return item["value"];
      //}),
    };
    return requests.postWithToken(
      `${apiUrl}/api/User/UpdateUserDetails`,
      data,
      access_token
    );
  },
  updateProfile: (access_token: string, data: any) => {
    return requests.postWithToken(
      `${apiUrl}/api/user/update`,
      data,
      access_token
    );
  },
  getUsers: (access_token: string) => {
    return requests.getWithToken(
      `${apiUrl}/odata/GetUsers`,
      access_token
    );
  },
  getAssignableRoles: (access_token: string) => {
    return requests.getWithToken(`${apiUrl}/odata/GetRoles`, access_token);
  },
};

const PumpData = {
  getPumpDataList: (
    access_token: string,
    currentPage: Number,
    pageSize: Number,
    url: string
  ) => {
    return requests.getWithToken(
      `${apiUrl}/odata/PumpList?$count=true${url}&$skip=${(currentPage - 1) * pageSize
      }&$top=${pageSize}`,
      access_token
    );
  },

  getPumpFamilies: (access_token: string) => {
    return requests.getWithToken(
      `${apiUrl}/api/QHSelection/PumpFamilies`,
      access_token
    );
  },

  getSubTypes: (pumpfamily: String, access_token: String) => {
    return requests.postWithToken(
      `${apiUrl}/api/QHSelection/SubTypes`,
      pumpfamily,
      access_token,
    );
  },

  getImpellerType: (access_token: String) => {
    return requests.getWithToken(
      `${apiUrl}/api/QHSelection/ImpellerType`,
      access_token
    );
  },

  getImpellerMat: (access_token: String) => {
    return requests.getWithToken(
      `${apiUrl}/api/QHSelection/ImpellerMat`,
      access_token
    );
  },

  getPumpFamiliesList: (access_token: String) => {
    return requests.getWithToken(
      `${apiUrl}/api/PumpSelection/PumpFamilies`,
      access_token
    );
  },

  getPumpSubTypes: (access_token: String, pumpFamily: String) => {
    return requests.getWithToken(
      `${apiUrl}/api/PumpSelection/PumpSubTypes?family=${pumpFamily}`,
      access_token
    );
  },

  getPumpSubTypeData: (
    access_token: String,
    pumpFamily: String,
    pumpSubType: String
  ) => {
    return requests.getWithToken(
      `${apiUrl}/api/PumpSelection/PumpSubTypeData?family=${pumpFamily}&subType=${pumpSubType}`,
      access_token
    );
  },

  getSearchPumpData: (access_token, searchData, currentPage, pageSize, url) => {
    return requests.postWithToken(
      `${apiUrl}/odata/QHPumpList?$count=true${url}&$skip=${(currentPage - 1) * pageSize
      }&$top=${pageSize}`, searchData, access_token
    );
  },

  getGraphData: (access_token, data) => {
    return requests.postWithToken(
      `${apiUrl}/api/QHGraph/GetQHGraph`,
      data,
      access_token
    );
  },

  getPSGraphData: (access_token, data) => {
    return requests.postWithToken(
      `${apiUrl}/api/PumpSelectionGraph/PSGetGraph`,
      data,
      access_token
    );
  },

  getLSGraphData: (access_token, data) => {
    return requests.postWithToken(
      `${apiUrl}/api/QHGraph/GetQHGraph`,
      data,
      access_token
    );
  },

  getRSGraphData: (access_token, data) => {
    return requests.postWithToken(
      `${apiUrl}/api/QHGraph/GetQHGraph`,
      data,
      access_token
    );
  },

  getLSPSGraphData: (access_token, data) => {
    return requests.postWithToken(
      `${apiUrl}/api/PumpSelectionGraph/PSGetGraph`,
      data,
      access_token
    );
  },

  getRSPSGraphData: (access_token, data) => {
    return requests.postWithToken(
      `${apiUrl}/api/PumpSelectionGraph/PSGetGraph`,
      data,
      access_token
    );
  },

  updateGraph: (access_token, data, canSave) => {
    return requests.postWithToken(
      `${apiUrl}/api/UpdateGraph/UpdateGraph/${canSave}`,
      data,
      access_token
    );
  },
  getPrintData: (access_token: String) => {
    return requests.getWithToken(
      `${apiUrl}/api/UpdateGraph/UpdateGraph`,
      access_token
    );
  },

  getGeneralData: (access_token: String, testNo: String) => {
    return requests.getWithToken(
      `${apiUrl}/api/pump?id=${testNo}`,
      access_token
    );
  },

  savePumpData: (access_token: String, data: any, unlock: any) => {
    return requests.postWithToken(
      `${apiUrl}/api/pump/save?unlock=${unlock}`,
      data,
      access_token
    );
  },

  unlockRecord: (access_token: String, data: any, unlock: any) => {
    return requests.postWithToken(
      `${apiUrl}/api/pump/UnlockRecord?unlock=${unlock}`,
      data,
      access_token
    );
  },

  getTestData: (access_token: String, data: any) => {
    return requests.postWithToken(`${apiUrl}/api/TestData`, data, access_token);
  },

  getNPSHData: (access_token: String, data: any) => {
    return requests.postWithToken(`${apiUrl}/api/NPSH`, data, access_token);
  },

  deletePumpData: (access_token: String, id: String) => {
    return requests.postWithToken(
      `${apiUrl}/api/pump/delete?id=${id}`,
      null,
      access_token
    );
  },
  saveTempPumpData: (access_token: String, data: any) => {
    return requests.postWithToken(
      `${apiUrl}/api/pump/tempSave`,
      data,
      access_token
    );
  },
  extendPumpDataSession: (access_token: String, id: String) => {
    return requests.getWithToken(
      `${apiUrl}/api/pump/ExtendAccHold?id=${id}`,
      access_token
    );
  }
};

const AdminData = {
  getAdminFamilyList: (access_token: string) => {
    return requests.getWithToken(
      `${apiUrl}/odata/PumpFamily`,
      access_token
    );
  },
  getAdminSubTypeList: (access_token: string) => {
    return requests.getWithToken(
      `${apiUrl}/odata/SubType`,
      access_token
    );
  },
  getVersion: (access_token: string) => {
    return requests.getWithToken(
      `${apiUrl}/odata/GetVersion`,
      access_token
    );
  },
  getAdminPumpData: (access_token: string, data: string) => {   // get data to list in pump screen when a family is selected.
    return requests.getWithToken(
      `${apiUrl}/odata/PumpFamilyData?family=${data}`,
      access_token,
    );
  },
  getSubTypeAvailability: (access_token: string, data: Object) => {
    return requests.postWithToken(
      `${apiUrl}/odata/SubTypeAvailability`,
      data,
      access_token,
    );
  },
  getDiamSpeedList: (access_token: string, data: Object) => {
    return requests.postWithToken(
      `${apiUrl}/odata/DiamSpeed`,
      data,
      access_token,
    );
  },
  getConstantsList: (access_token: string, data: Object) => {
    return requests.getWithToken(
      `${apiUrl}/odata/Constants?family=${data}`,
      access_token,
    );
  },
  getAccessStatus: (access_token: string) => {
    return requests.getWithToken(
      `${apiUrl}/api/MaintenanceToolRelease/GetAccessStatus`,
      access_token,
    );
  },
  releaseDB: (access_token: string, data: string) => {
    return requests.getWithToken(
      `${apiUrl}/api/MaintenanceToolRelease/Release?ReleaseComment=${data}`,
      access_token,
    );
  },
  getReleaseLog: (access_token: string) => {
    return requests.getWithToken(
      `${apiUrl}/api/MaintenanceToolRelease/GetCurrentReleaseStatus`,
      access_token,
    );
  },
  clearAccessStatus: (access_token: string) => {
    return requests.getWithToken(
      `${apiUrl}/api/User/ClearSelfAccStatus`,
      access_token,
    );
  },
  overrideEditing: (access_token: string) => {
    return requests.getWithToken(
      `${apiUrl}/api/User/ClearOtherAccStatus`,
      access_token,
    );
  },
  getLockValues: (access_token: string, value: any) => {
    return requests.postWithToken(
      `${apiUrl}/api/MaintenanceToolRelease/UpdateAcc`,
      value,
      access_token,
    );
  },
  getMapDetails: (access_token: string, id: any) => {
    return requests.getWithToken(
      `${apiUrl}/odata/PumpFamilyGeoAppMap?familyId=${id}`, 
      access_token,
    );
  },
  getMapSubTypeDetails: (access_token: string, id: any) => {
    return requests.getWithToken(
      `${apiUrl}/odata/SubTypeGeoAppMap?subTypeId=${id}`, 
      access_token,
    );
  },
  getMapPumpsDetails: (access_token: string, id: any) => {
    return requests.getWithToken(
      `${apiUrl}/odata/SubTypeDataGeoAppMap?subTypeDataId=${id}`, 
      access_token,
    );
  },
  getImpTypeList: (access_token: string) => {
    return requests.getWithToken(
      `${apiUrl}/odata/PumpFamily`,
      access_token
    );
  },
  getImpMatList: (access_token: string) => {
    return requests.getWithToken(
      `${apiUrl}/odata/PumpFamily`,
      access_token
    );
  },
  createPumpFamily: (access_token: string, data: Object) => {
    return requests.postWithToken(
      `${apiUrl}/api/MaintenanceToolPumpFamily/UpdatePumpFamily`,
      {
        family: data.family,
        available: data.available,
        ecoType: data.ecoType,
      },
      access_token
    );
  },
  createMapFamily: (access_token: string, data: Object) => {
    return requests.postWithToken(
      `${apiUrl}/api/MaintenanceToolGeoAppMap/AddFamilyGeoAppMap`,
      {
        family: data.family,
        geoLocation: data.geoLocation,
        application: data.application,
      },
      access_token
    );
  },
  createMapSubType: (access_token: string, data: Object) => {
    return requests.postWithToken(
      `${apiUrl}/api/MaintenanceToolGeoAppMap/AddSubTypeGeoAppMap`,
      {
        family: data.family,
        subType: data.subType,
        geoLocation: data.geoLocation,
        application: data.application,
      },
      access_token
    );
  },
  createMapPumps:  (access_token: string, data: Object) => {
    return requests.postWithToken(
      `${apiUrl}/api/MaintenanceToolGeoAppMap/AddSubTypeDataGeoAppMap`,
      {
        family: data.family,
        subType: data.subType,
        type: data.type,
        impMat: data.impMat,
        impType: data.impType,
        geoLocation: data.geoLocation,
        application: data.application,
      },
      access_token
    );
  },
  createSubType: (access_token: string, data: Object) => {
    return requests.postWithToken(
      `${apiUrl}/api/MaintenanceToolSubType/UpdateSubType`,
      data,
      access_token
    );
  },
  createPump: (access_token: string, data: Object) => {    // When new pump is to be added
    return requests.postWithToken(
      `${apiUrl}/api/MaintenanceToolPump/UpdatePumpData`,
       data,
      access_token
    );
  },
  createDiamSpeed: (access_token: string, data: Object) => {    
    return requests.postWithToken(
      `${apiUrl}/api/MaintenanceToolPump/UpdateDiamSpeed`,
       data,
      access_token
    );
  },
  createImpType: (access_token: string, data: Object) => {
    return requests.postWithToken(
      `${apiUrl}/api/MaintenanceToolPumpFamily/UpdateImpType`,
      data,
      access_token
    );
  },
  createImpMat: (access_token: string, data: Object) => {
    return requests.postWithToken(
      `${apiUrl}/api/MaintenanceToolPumpFamily/UpdateImpMat`,
      data,
      access_token
    );
  },
  updatePumpFamily: (access_token: string, data: Object) => {
    return requests.postWithToken(
      `${apiUrl}/api/MaintenanceToolPumpFamily/UpdatePumpFamily`,
      data,
      access_token
    );
  },
  updateImpType: (access_token: string, data: Object) => {
    return requests.postWithToken(
      `${apiUrl}/api/MaintenanceToolPumpFamily/UpdateImpType`,
      data,
      access_token
    );
  },
  updateImpMat: (access_token: string, data: Object) => {
    return requests.postWithToken(
      `${apiUrl}/api/MaintenanceToolPumpFamily/UpdateImpMat`,
      data,
      access_token
    );
  },
  updateVersion: (access_token: string, data: Object) => {
    return requests.postWithToken(
      `${apiUrl}/api/MaintenanceToolVersion/UpdateVersion`,
      data,
      access_token 
    );
  },
  removePumpFamily: (
    access_token: String,
    id: string
  ) => {
    return requests.postWithToken(
      `${apiUrl}/api/MaintenanceToolPumpFamily/DeletePumpFamily?id=${id}`,
      null,
      access_token
    );
  },
  removeMapFamily: (
    access_token: String,
    id: string
  ) => {
    return requests.postWithToken(
      `${apiUrl}/api/MaintenanceToolGeoAppMap/DeleteFamilyGeoAppMap?id=${id}`,
      null,
      access_token
    );
  },
  removeMapSubType: (
    access_token: String,
    id: string
  ) => {
    return requests.postWithToken(
      `${apiUrl}/api/MaintenanceToolGeoAppMap/DeleteSubTypeGeoAppMap?id=${id}`,
      null,
      access_token
    );
  },
  removeMapPumps: (
    access_token: String,
    id: string
  ) => {
    return requests.postWithToken(
      `${apiUrl}/api/MaintenanceToolGeoAppMap/DeleteSubTypeDataGeoAppMap?id=${id}`,
      null,
      access_token
    );
  },
  removeSubType: (
    access_token: String,
    id: string
  ) => {
    return requests.postWithToken(
      `${apiUrl}/api/MaintenanceToolSubType/DeleteSubType?id=${id}`,
      null,
      access_token
    );
  },
  updateSubType: (access_token: string, data: Object) => {
    return requests.postWithToken(
      `${apiUrl}/api/MaintenanceToolSubType/UpdateSubType`,
      data,
      access_token
    );
  },
  updateSubTypeAvail: (access_token: string, data: Object) => {
    return requests.postWithToken(
      `${apiUrl}/api/MaintenanceToolPump/UpdateSubTypeData`,
      data,
      access_token
    );
  },
  updateDiamSpeed: (access_token: string, data: Object) => {
    return requests.postWithToken(
      `${apiUrl}/api/MaintenanceToolPump/UpdateDiamSpeed`,
      data,
      access_token
    );
  },
  updateConstants: (access_token: string, data: Object) => {
    return requests.postWithToken(
      `${apiUrl}/api/MaintenanceToolPump/UpdateConstants`,
      data,
      access_token
    );
  },
  copyPump: (access_token: string, data: Object) => {    // when copy button is clicked, to get data from backend.  
    return requests.getWithToken(
      `${apiUrl}/api/MaintenanceToolPump/CopyPumpData?pumpId=${data}`, 
      access_token
    );
  },
  updatePump: (access_token: string, data: Object) => {    // when any changes are made in pump edit page,to save that in db.  
    return requests.postWithToken(
      `${apiUrl}/api/MaintenanceToolPump/UpdatePumpData`, 
      data,
      access_token
    );
  },
  getPumpEditData: (access_token: string, data: Object) => {
    return requests.getWithToken(
      `${apiUrl}/api/MaintenanceToolPump/PumpDetails?pumpId=${data}`, // (get values which is already in db when edit button is hit, to load data in edit page)
      access_token
    );
  },
  createSubTypeAvail: (access_token: string, data: Object) => {
    return requests.postWithToken(
      `${apiUrl}/api/MaintenanceToolPump/UpdateSubTypeData`,
       data,
      access_token
    );
  },
  removeSubTypeAvail: (
    access_token: String,
    id: string
  ) => {
    return requests.postWithToken(
      `${apiUrl}/api/MaintenanceToolPump/DeleteSubTypeData?id=${id}`,
      null,
      access_token
    );
  },
  removePump: (
    access_token: String,
    id: string
  ) => {
    return requests.postWithToken(
      `${apiUrl}/api/MaintenanceToolPump/DeletePumpData?id=${id}`,
      null,
      access_token
    );
  },
  removeDiamSpeed: (
    access_token: String,
    id: string
  ) => {
    return requests.postWithToken(
      `${apiUrl}/api/MaintenanceToolPump/DeleteDiamSpeed?id=${id}`,
      null,
      access_token
    );
  },
  removeImpType: (
    access_token: String,
    id: string
  ) => {
    return requests.postWithToken(
      `${apiUrl}/api/MaintenanceToolPumpFamily/DeleteImpType?id=${id}`,
      null,
      access_token
    );
  },
};

const Import = {
  importData: (access_token: String, data: any) => {
    var dataToSend = new FormData();

    if (data && data.files.length > 0) {
      for (var i in data.files) {
        dataToSend.append(`file${i}`, data.files[i]);
      }
    }

    return requests.uploadWithToken(
      `${apiUrl}/api/import/dbfile`,
      dataToSend,
      access_token
    );
  },
};

const Export = {
  exportData: (access_token: String, data: any) => {
    return requests.postWithToken(
      `${apiUrl}/api/csv/export`,
      data,
      access_token
    );
  },
};

const UploadCSV = {
  uploadCSV: (access_token: String, data: any) => {
    var dataToSend = new FormData();

    if (data && data.files.length > 0) {
      for (var i in data.files) {
        dataToSend.append(`file${i}`, data.files[i]);
      }
    }

    return requests.uploadWithToken(
      `${apiUrl}/api/csv/upload`,
      dataToSend,
      access_token
    );
  },
};

export default {
  Auth,
  User,
  PumpData,
  AdminData,
  Import,
  Export,
  UploadCSV,
};
