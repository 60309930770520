import {
  getToken, modifyImpellerList, modifyImpellerMatList, modifyPumpFamilyList, modifyPumpSubFamilyList,
} from "../../helpers/common";
import agent from "../../services/agent";
import { GET_SEARCH_DATA_ERROR } from "../constants/pumpData.constants";
import swal from "sweetalert";
import {
  SET_ACTIVE_PUMP_FAMILY,
  GET_PUMP_FAMILY_LIST_PENDING,
  SET_PUMP_FAMILY_LIST,
  SET_PUMP_FAMILY_LIST_PENDING,
  SET_ACTIVE_PUMP_SUBTYPE,
  SET_PUMPFAMILY_LIST_ASYNC,
  SET_IMPELLER_LIST_ASYNC,
  SET_IMPELLER_MATERIAL_LIST_ASYNC,
  CLEAR_PUMP_DATA_LIST_FILTER,
  SEARCH_DATA_ERROR,
  SET_FLOWMETER_LIST_ASYNC,
  SET_PUMP_SUBFAMILY_LIST_ASYNC,
  SET_MODIFIED_DATA,
  SET_PUMP_DATA_LIST_FILTER,
  GET_SEARCH_DATA_PENDING,
  SET_SEARCH_PUMP_DATA,
  GET_SEARCH_DATA_SUCCESS,
  SET_UNIT_VALUE_SELECTION,
  GET_GRAPH_DATA_SUCCESS,
  SET_GRAPH_ERROR,
  SET_GRAPH_DATA,
  SET_PRINT_DATA,
  GET_PRINT_DATA_SUCCESS,
  SET_QH_SELECTION_FILTERED_PUMP_DATA_IDS,
  UPDATE_GRAPH_DATA_SUCCESS,
  UPDATE_GRAPH_DATA_ERROR,
  UPDATE_GRAPH_DATA_PENDING,
  SET_CURRENT_PUMP_DETAILS,
  SET_CURRENT_FIELD_CHANGE,
  SET_PS_GRAPH_DATA,
  SET_PUMP_SUB_TYPE,
  SET_CURRENT_PS_PUMP_DETAILS,
  SET_GRAPHTYPE,
  CLEAR_SEARCH_PUMP_DATA,
  OPEN_SELECTED_PUMPS_MODAL,
  CLOSE_SELECTED_PUMPS_MODAL,
  SET_TOTAL_CURVE,
  SET_PARALLEL_PUMP,
  SET_MENU_OPTIONS,
  SET_NEXT_DISABLE,
  SET_NEXT_DISABLE_FALSE,
  OPEN_HELP_MODEL,
  CLOSE_HELP_MODEL
} from "../constants/qh-selection.constants";
import { showSnackbarStatus } from "./snackbar.actions";

export const setIsDataModified = (): Action => ({
  type: SET_MODIFIED_DATA,
});

export const clearPumpDataFilter = (): Action => ({
  type: CLEAR_PUMP_DATA_LIST_FILTER,
});

export const getPumpFamilyListPending = (): Action => ({
  type: GET_PUMP_FAMILY_LIST_PENDING,
});

export const setpumpFamilyPending = (): Action => ({
  type: SET_PUMP_FAMILY_LIST_PENDING,
});

export const setPumpFamilyList = (data: any): Action => ({
  type: SET_PUMP_FAMILY_LIST,
  payload: data,
});

export const setActivePumpFamily = (activePumpFamily: string): Action => ({
  type: SET_ACTIVE_PUMP_FAMILY,
  payload: activePumpFamily,
});

export const setActivePumpSubType = (activePumpSubType: string): Action => ({
  type: SET_ACTIVE_PUMP_SUBTYPE,
  payload: activePumpSubType,
});

export const setpumpFamilyList = (flowMetersOdata: any): Action => ({
  type: SET_PUMP_FAMILY_LIST,
  payload: { flowMetersOdata },
});

export const setPumpSubType = (subFamilyList: any): Action => ({
  type: SET_PUMP_SUB_TYPE,
  payload: subFamilyList,
});

export const searchdetailsError = (errorMessage: string): Action => ({
  type: SEARCH_DATA_ERROR,
  payload: { errorMessage },
});

export const getSearchDataPending = (): Action => ({
  type: GET_SEARCH_DATA_PENDING,
});

export const setSearchPumpData = (data: any): Action => ({
  type: SET_SEARCH_PUMP_DATA,
  payload: { pumpListOdata: data },
});

export const clearSearchPumpData = (data: any): Action => ({
  type: CLEAR_SEARCH_PUMP_DATA,
  payload: { pumpListOdata: data },
});

export const setGraphData = (data: any): Action => ({
  type: SET_GRAPH_DATA,
  payload: data,
});

export const setPSGraphData = (data: any): Action => ({
  type: SET_PS_GRAPH_DATA,
  payload: data,
});

export const setPrintData = (data: any): Action => ({
  type: SET_PRINT_DATA,
  payload: data,
});

export const getSearchDataSuccess = (): Action => ({
  type: GET_SEARCH_DATA_SUCCESS,
});

export const getSearchDataError = (): Action => ({
  type: GET_SEARCH_DATA_ERROR,
});

export const getGraphDataSuccess = (): Action => ({
  type: GET_GRAPH_DATA_SUCCESS,
});

export const setGraphError = (data): Action => ({
  type: SET_GRAPH_ERROR,
  payload: data,
});

export const getPrintDataSuccess = (): Action => ({
  type: GET_PRINT_DATA_SUCCESS,
})

export const setUnitValueSelection = (data: any): Action => ({
  type: SET_UNIT_VALUE_SELECTION,
  payload: data,
});

export const setPumpDataFilter = (data: any): Action => ({
  type: SET_PUMP_DATA_LIST_FILTER,
  payload: data,
});

export const openSelectedPumpsModal = (): Action => ({
  type: OPEN_SELECTED_PUMPS_MODAL,
});

export const closeSelectedPumpsModal = (): Action => ({
  type: CLOSE_SELECTED_PUMPS_MODAL,
});

export const setTotalCurve = (data): Action => ({
  type: SET_TOTAL_CURVE,
  payload: data
})

export const setMenuOptions = (data: any): Action => ({
  type: SET_MENU_OPTIONS,
  payload: data,
});

export const setQHSelectionFilteredPumpDataIds = (pumpDataIds: any, pumpIndexes: any, selectedRows: any): Action => ({
  type: SET_QH_SELECTION_FILTERED_PUMP_DATA_IDS,
  payload: { pumpDataIds, pumpIndexes, selectedRows },
});

export const setNextDisable = (): Action => ({
  type: SET_NEXT_DISABLE,
});

export const setNextDisableFalse = () : Action => ({
  type: SET_NEXT_DISABLE_FALSE,
});

export const openHelpModel = (): Action => ({
  type: OPEN_HELP_MODEL,
});

export const closeHelpModel = (): Action => ({
  type: CLOSE_HELP_MODEL,
});

export const setFlowMetersListForAsyncSelect = (
  FlowMetersList: any[]
): Action => ({
  type: SET_FLOWMETER_LIST_ASYNC,
  payload: {
    FlowMetersListAsync: FlowMetersList,
  },
});

export const searchDatafilter = () => async (dispatch: Function, getState: Function) => {
  const currentState = getState();
  const { searchData } =
    currentState;
  if (!searchData) {
    dispatch(searchdetailsError("Please fill required details."));
    return;
  }
};

export const getPumpFamilyListforAsyncSelect =
  (searchValue?: String, callback?: Function) => (dispatch: Function) => {
    getToken(dispatch).then((accessToken) => {
      agent.PumpData.getPumpFamilies(accessToken)
        .then((pumpFamilyList) => {
          dispatch(setPumpFamilyListforAsyncSelect(pumpFamilyList));
          var familyList = modifyPumpFamilyList(pumpFamilyList);
          callback && callback(familyList);
        })
        .catch((err) => {
          console.log("getPumpFamilyListforAsyncSelect", err);
          dispatch(showSnackbarStatus("Something Went Wrong"));
          callback && callback();
        });
    });
  };

export const getPumpSubTypeListforAsyncSelect =
  (pumpfamily?: String, callback?: Function) => (dispatch: Function) => {
    getToken(dispatch).then((accessToken) => {
      agent.PumpData.getSubTypes(pumpfamily, accessToken)
        .then((subTypeList) => {
          dispatch(setPumpSubTypeListforAsyncSelect(subTypeList));
          var subFamilyList = modifyPumpSubFamilyList(subTypeList);

          dispatch(setPumpSubType(subFamilyList));
          callback && callback(subFamilyList);
        })
        .catch((err) => {

          dispatch(showSnackbarStatus("Something Went Wrong"));
          callback && callback();
        });
    });
  };

export const getImpellerTypeforAsyncSelect =
  (searchValue?: String, callback?: Function) => (dispatch: Function) => {
    getToken(dispatch).then((accessToken) => {
      agent.PumpData.getImpellerType(accessToken)
        .then((impellerList) => {
          dispatch(setImpellerTypeforAsyncSelect(impellerList));
          var impellerTypeList = modifyImpellerList(impellerList);
          callback && callback(impellerTypeList);
        })
        .catch((err) => {
          console.log("getImpellerTypeforAsyncSelect", err);
          dispatch(showSnackbarStatus("Something Went Wrong"));
          callback && callback();
        });
    });
  };

export const getImpellerMaterialforAsyncSelect =
  (searchValue?: String, callback?: Function) => (dispatch: Function) => {
    getToken(dispatch).then((accessToken) => {
      agent.PumpData.getImpellerMat(accessToken)
        .then((impellerMatList) => {
          dispatch(setImpellerMaterialforAsyncSelect(impellerMatList));
          var impellerMaterialList = modifyImpellerMatList(impellerMatList);
          callback && callback(impellerMaterialList);
        })
        .catch((err) => {
          console.log("getImpellerMaterialforAsyncSelect", err);
          dispatch(showSnackbarStatus("Something Went Wrong"));
          callback && callback();
        });
    });
  };

  const showAlert = (errorMessage) => {
    swal({
      title: "Warning!",
      text: errorMessage,
      icon: "warning",
      button: "Ok",
    });
  };

export const getSearchPumpData =
  (searchData, currentPage, pagesize, url) => (dispatch: Function, getState: Function) => {
    dispatch(getSearchDataPending());
    return getToken(dispatch).then((accessToken) => {
      return agent.PumpData.getSearchPumpData(accessToken, searchData, currentPage, pagesize, url)
        .then((searchPumpData) => {
          if (searchPumpData.value.length === 0) {
            showAlert("No Pumps Found to Match the Criteria, Try Again with New Criteria");
            dispatch(setSearchPumpData(searchPumpData));
            dispatch(getSearchDataSuccess());
            return Promise.resolve(searchPumpData);
          } else {
            dispatch(setSearchPumpData(searchPumpData));
            dispatch(getSearchDataSuccess());
            return Promise.resolve(searchPumpData);
          }
        })
        .catch((err) => {
          dispatch(getSearchDataError());
          console.log("getSearchPumpData server error", err);
          dispatch(
            showSnackbarStatus("Something went wrong. Please try again later")
          );
        });
    });
  };

export const setCurrentPumpDetails = (pumpId, subType, pumpSpeed): Action => ({
  type: SET_CURRENT_PUMP_DETAILS,
  payload: { pumpId, subType, pumpSpeed },
});

export const getGraphData = (pumpId, subType, pumpSpeed, RefId) => (dispatch: Function, getState: Function) => {
  const currentState = getState();
  dispatch(setGraphError(null));
  console.log("currentstate", currentState);
  const { qhSelection: { filter: {
    bearings,
    capacity,
    frequency,
    head,
    highOperRegion,
    lowOperRegion,
    selected_density_filter,
    selected_viscosity_filter,
    soortMassa,
    tolerance,
    viscosity,
    speed,
    npsha,
    efficiency
  }, selectedUnit, totalCurve, parallelPump, staticHead,
  } } = currentState;
  var dataToSend = {
    RefId: RefId,
    pumpId: pumpId,
    subType: subType,
    speed: pumpSpeed,
    searchCriteria: {
      bearings: Number(bearings),
      frequency: Number(frequency),
      speed: Number(speed) || null,
      lowOperRegion: Number(lowOperRegion),
      highOperRegion: Number(highOperRegion),
      tolerance: Number(tolerance),
      npsha: Number(npsha) || null,
      efficiency: Number(efficiency) || null,
      soortMassa: Number(soortMassa),
      cboDensity: selected_density_filter,
      isMetric: selectedUnit === "us" ? false : true,
      isOnlyPositiveTol: false,
      viscosity: Number(viscosity),
      cboViscosity: selected_viscosity_filter,
      capacity: Number(capacity),
      head: Number(head)
    },
    menuOptions: {
      staticHead: Number(staticHead),
      menuTotalCurve : totalCurve,
      menuParallelPumpOptions : parallelPump
    }
  }
  dispatch(setCurrentPumpDetails(pumpId, subType, pumpSpeed));
  return getToken(dispatch).then((accessToken) => {
    return agent.PumpData.getGraphData(accessToken, dataToSend)
      .then((graphData) => {
        dispatch(setGraphData(graphData));
        dispatch(getGraphDataSuccess());
        return Promise.resolve(graphData);
      })
      .catch((err) => {
        if (err && err.response) {
          const { message } = err.response.text;
          dispatch(
            showSnackbarStatus(`Unable to retrieve graph data. ${message}`)
          );
          return;
        }
        dispatch(setGraphError(err && err.response.text));
        console.log("getGraphData server error", err);
        dispatch(
          showSnackbarStatus("Something went wrong. Please try again later")
        );
      });
  });
};

export const setCurrentPSPumpDetails = (subType, family, pType, waaierType, waaierMat): Action => ({
  type: SET_CURRENT_PS_PUMP_DETAILS,
  payload: { subType, family, pType, waaierType, waaierMat },
});

export const getPSGraphData = (family, pType, subType, waaierMat, waaierType, totalCurve) => (dispatch: Function, getState: Function) => {
  const currentState = getState();
  dispatch(setGraphError(null));
  const { qhSelection: { filter: {
    bearings,
    capacity,
    frequency,
    head,
    highOperRegion,
    lowOperRegion,
    selected_density_filter,
    selected_viscosity_filter,
    soortMassa,
    tolerance,
    viscosity,
    speed,
    npsha,
    efficiency
  }, selectedUnit, totalCurve, parallelPump,  staticHead
   } } = currentState;
  var dataToSend = {
    pumpDetail: {
      family: family,
      subType: subType || "",
      pType: pType,
      waaierType: waaierType,
      waaierMat: waaierMat
    },
    searchCriteria: {
      bearings: Number(bearings) || 0,
      frequency: Number(frequency) || null,
      speed: Number(speed) || null,
      lowOperRegion: Number(lowOperRegion) || 20,
      highOperRegion: Number(highOperRegion) || 120,
      tolerance: Number(tolerance) || 5,
      npsha: Number(npsha) || null,
      efficiency: Number(efficiency) || null,
      soortMassa: Number(soortMassa) || null,
      cboDensity: Number(selected_density_filter) || 0,
      isMetric: selectedUnit === "us" ? false : true,
      isOnlyPositiveTol: false,
      viscosity: Number(viscosity) || null,
      cboViscosity: Number(selected_viscosity_filter) || selectedUnit === 'metric' ? 1 : 0,
      capacity: Number(capacity) || null,
      head: Number(head) || null
    },
    menuOptions: {
      staticHead: Number(staticHead),
      menuTotalCurve : totalCurve,
      menuParallelPumpOptions : parallelPump
    }
  }
  dispatch(setCurrentPSPumpDetails(subType, family, pType, waaierType, waaierMat));
  return getToken(dispatch).then((accessToken) => {
    return agent.PumpData.getPSGraphData(accessToken, dataToSend)
      .then((graphData) => {
        dispatch(setPSGraphData(graphData));
        dispatch(setPrintData(graphData));
        dispatch(getGraphDataSuccess());
        return Promise.resolve(graphData);
      })
      .catch((err) => {
        if (err && err.response) {
          const { message } = err.response.text;
          dispatch(setGraphError(err && err.response.text));
          dispatch(
            showSnackbarStatus(`Unable to retrieve graph data. ${message}`)
          );
        }
      });
  });
};


export const getPrintData = () => (dispatch: Function) => {
  return getToken(dispatch).then((accessToken) => {
    return agent.PumpData.getPrintData(accessToken)
      .then((graphData) => {
        dispatch(setPrintData(graphData));
        dispatch(getPrintDataSuccess());
        return Promise.resolve(graphData);
      })
      .catch((err) => {
        if (err && err.response) {
          const { message } = err.response.body;
          dispatch(
            showSnackbarStatus(`Unable to retrieve Print data. ${message}`)
          );
          return;
        }
        console.log("getPrintData server error", err);
        dispatch(
          showSnackbarStatus("Something went wrong. Please try again later")
        );
      });
  });
};

export const setCurrentFieldChange = (fieldName: any): Action => ({
  type: SET_CURRENT_FIELD_CHANGE,
  payload: fieldName,
});

export const updateGraphDataSuccess = (): Action => ({
  type: UPDATE_GRAPH_DATA_SUCCESS,
});

export const updateGraphDataError = (): Action => ({
  type: UPDATE_GRAPH_DATA_ERROR,
});

export const updateGraphDataPending = (): Action => ({
  type: UPDATE_GRAPH_DATA_PENDING,
});

export const setGraphType = (assignedTarget): Action => ({
  type: SET_GRAPHTYPE,
  payload: assignedTarget
})

export const setParallelPumps = (data): Action => ({
  type: SET_PARALLEL_PUMP,
  payload: data
})

export const updateGraph = (canSave = false, RefId, orificeCheckbox) => (dispatch: Function, getState: Function) => {
  const currentState = getState();
  dispatch(setGraphError(null));
  
  const graphType = currentState && currentState.qhSelection && currentState.qhSelection.GraphType
  console.log("again check",canSave, RefId, orificeCheckbox)
  if (graphType === 'qs') {
    const currentState = getState();
    const { graphData: { qhData }, filter: {
      bearings,
      capacity,
      frequency,
      head,
      highOperRegion,
      lowOperRegion,
      selected_density_filter,
      selected_viscosity_filter,
      soortMassa,
      tolerance,
      viscosity,
      speed,
      npsha,
      efficiency
    },
    staticHead, selectedUnit, totalCurve, parallelPump, currentPumpDetails: { pumpId, subType, pumpSpeed }, currentFieldChange } =
      currentState && currentState.qhSelection;

    const { formGraphData, selectedDutypoints } = currentState && currentState.form;

    const dataToSend = {
      RefId: RefId,
      pumpId: pumpId,
      subType: subType,
      speed: pumpSpeed,
      searchCriteria: {
        bearings: Number(bearings),
        frequency: Number(frequency),
        speed: Number(speed) || null,
        lowOperRegion: Number(lowOperRegion),
        highOperRegion: Number(highOperRegion),
        tolerance: Number(tolerance),
        npsha: Number(npsha) || null,
        efficiency: Number(efficiency) || null,
        soortMassa: Number(soortMassa),
        cboDensity: selected_density_filter,
        isMetric: selectedUnit === "us" ? false : true,
        isOnlyPositiveTol: false,
        viscosity: Number(viscosity),
        cboViscosity: selected_viscosity_filter,
        capacity: Number(capacity),
        head: Number(head)
      },
      dutyPointsController: {
        ...formGraphData
      },
      qhData: {
        ...qhData
      },
      currentFieldChange: currentFieldChange,
      currentSelectionScreen: graphType,
      menuOptions: {
        staticHead: Number(staticHead),
        menuTotalCurve : totalCurve,
        menuParallelPumpOptions : parallelPump
      },
      orificeCheckbox: {
        checkbox1:  orificeCheckbox && orificeCheckbox.orifice1,
        checkbox2:  orificeCheckbox && orificeCheckbox.orifice2,
        checkbox3:  orificeCheckbox && orificeCheckbox.orifice3,
        checkbox4:  orificeCheckbox && orificeCheckbox.orifice4,
        // checkbox1: orificeCheckbox ?  (orificeCheckbox && orificeCheckbox.orifice1) : (selectedDutypoints && selectedDutypoints.orifice1),
        // checkbox2: orificeCheckbox ?  (orificeCheckbox && orificeCheckbox.orifice2) : (selectedDutypoints && selectedDutypoints.orifice2),
        // checkbox3: orificeCheckbox ?  (orificeCheckbox && orificeCheckbox.orifice3) : (selectedDutypoints && selectedDutypoints.orifice3),
        // checkbox4: orificeCheckbox ?  (orificeCheckbox && orificeCheckbox.orifice4) : (selectedDutypoints && selectedDutypoints.orifice4)
      }
    };

    dispatch(updateGraphDataPending());
    getToken(dispatch).then((accessToken) => {
      agent.PumpData.updateGraph(accessToken, dataToSend, canSave)
        .then((graphData) => {
          dispatch(updateGraphDataSuccess());
          dispatch(setPrintData(graphData));
          dispatch(setNextDisableFalse());
          if (!canSave)
            dispatch(setGraphData(graphData));
        })
        .catch((err) => {
          if (err && err.response) {
            const  message = err.response.text;
            dispatch(updateGraphDataError(message));
            // dispatch(setGraphError(err && err.response.text));
              return;
            }
            console.log("Update graph server error", err);
            dispatch(
              showSnackbarStatus("Something went wrong. Please try again later")
            );
        });
    });
  }
  else {
      const currentState = getState();
      const { psGraphData: { qhData }, filter: {
        bearings,
        capacity,
        frequency,
        head,
        highOperRegion,
        lowOperRegion,
        selected_density_filter,
        selected_viscosity_filter,
        soortMassa,
        tolerance,
        viscosity,
        speed,
        npsha,
        efficiency
      },  staticHead , selectedUnit, totalCurve, parallelPump, currentPSPumpDetails: { subType }, currentFieldChange } =
        currentState && currentState.qhSelection;
      const { formGraphData, selectedDutypoints } = currentState && currentState.form;
      console.log("currentState && currentState.form",currentState && currentState.form)
      const dataToSend = {
        pumpId: qhData.pumpId,
        subType: subType || "",
        speed: qhData.n1,
        searchCriteria: {
          bearings: Number(bearings),
          frequency: Number(frequency),
          speed: Number(speed) || null,
          lowOperRegion: Number(lowOperRegion),
          highOperRegion: Number(highOperRegion),
          tolerance: Number(tolerance),
          npsha: Number(npsha) || null,
          efficiency: Number(efficiency) || null,
          soortMassa: Number(soortMassa),
          cboDensity: selected_density_filter,
          isMetric: selectedUnit === "us" ? false : true,
          isOnlyPositiveTol: false,
          viscosity: Number(viscosity),
          cboViscosity: selected_viscosity_filter,
          capacity: Number(capacity),
          head: Number(head)
        },
        dutyPointsController: {
          ...formGraphData
        },
        qhData: {
          ...qhData
        },
        currentFieldChange: currentFieldChange,
        currentSelectionScreen: graphType,
        menuOptions: {
          staticHead: Number(staticHead),
          menuTotalCurve : totalCurve,
          menuParallelPumpOptions : parallelPump
        },
        orificeCheckbox: {
          checkbox1:  orificeCheckbox && orificeCheckbox.orifice1,
        checkbox2:  orificeCheckbox && orificeCheckbox.orifice2,
        checkbox3:  orificeCheckbox && orificeCheckbox.orifice3,
        checkbox4:  orificeCheckbox && orificeCheckbox.orifice4,
          // checkbox1: orificeCheckbox ?  (orificeCheckbox && orificeCheckbox.orifice1) : (selectedDutypoints && selectedDutypoints.orifice1),
          // checkbox2: orificeCheckbox ?  (orificeCheckbox && orificeCheckbox.orifice2) : (selectedDutypoints && selectedDutypoints.orifice2),
          // checkbox3: orificeCheckbox ?  (orificeCheckbox && orificeCheckbox.orifice3) : (selectedDutypoints && selectedDutypoints.orifice3),
          // checkbox4: orificeCheckbox ?  (orificeCheckbox && orificeCheckbox.orifice4) : (selectedDutypoints && selectedDutypoints.orifice4)
        }
      };
      dispatch(updateGraphDataPending());
      getToken(dispatch).then((accessToken) => {
        agent.PumpData.updateGraph(accessToken, dataToSend, canSave)
          .then((PsgraphData) => {
            dispatch(updateGraphDataSuccess());
            dispatch(setPrintData(PsgraphData));
            dispatch(setNextDisableFalse());
            if (!canSave)
              dispatch(setPSGraphData(PsgraphData));
          })
          .catch((err) => {
            if (err && err.response) {
              const { message } = err.response.text;
              dispatch(updateGraphDataError(message));
              dispatch(setGraphError(err && err.response.text));
              return;
            }
            console.log("Update graph server error", err);
            dispatch(
              showSnackbarStatus("Something went wrong. Please try again later")
            );
          });
      });
  }

}

export const setPumpFamilyListforAsyncSelect = (
  pumpFamilyList: any[]
): Action => ({
  type: SET_PUMPFAMILY_LIST_ASYNC,
  payload: { pumpFamilyListAsync: pumpFamilyList, },
});

export const setPumpSubTypeListforAsyncSelect = (
  subTypeList: any[]
): Action => ({
  type: SET_PUMP_SUBFAMILY_LIST_ASYNC,
  payload: { pumpSubFamilyListAsync: subTypeList, },
});

export const setImpellerTypeforAsyncSelect = (
  impellerList: any[]
): Action => ({
  type: SET_IMPELLER_LIST_ASYNC,
  payload: { impellerListAsync: impellerList, },
});

export const setImpellerMaterialforAsyncSelect = (
  impellerMaterialList: any[]
): Action => ({
  type: SET_IMPELLER_MATERIAL_LIST_ASYNC,
  payload: { impellerMatrialListAsync: impellerMaterialList, },
});


