export const OPEN_CREATE_PUMPFAMILY_MODAL = "OPEN_CREATE_PUMPFAMILY_MODAL";
export const CLOSE_CREATE_PUMPFAMILY_MODAL = "CLOSE_CREATE_PUMPFAMILY_MODAL";
export const OPEN_CREATE_SUBTYPE_MODAL = "OPEN_CREATE_SUBTYPE_MODAL";
export const CLOSE_CREATE_SUBTYPE_MODAL = "CLOSE_CREATE_SUBTYPE_MODAL";
export const SET_ADMINFAMILY_PENDING = "SET_ADMINFAMILY_PENDING";
export const SET_ADMINFAMILY_LIST = "SET_ADMINFAMILY_LIST";
export const SET_ADMINSUBTYPE_PENDING = "SET_ADMINSUBTYPE_PENDING";
export const SET_ADMINSUBTYPE_LIST = "SET_ADMINSUBTYPE_LIST";
export const CREATE_PUMPFAMILY_ERROR = "CREATE_PUMPFAMILY_ERROR";
export const CREATE_PUMPFAMILY_PENDING = "CREATE_PUMPFAMILY_PENDING";
export const CLEAR_PUMPFAMILY_ERROR = "CLEAR_PUMPFAMILY_ERROR";
export const CREATE_PUMPFAMILY_SUCCESS = "CREATE_PUMPFAMILY_SUCCESS";
export const SET_PUMPFAMILY_DETAILS = "SET_PUMPFAMILY_DETAILS";
export const OPEN_EDIT_PUMPFAMILY_MODAL = "OPEN_EDIT_PUMPFAMILY_MODAL";
export const CLOSE_EDIT_PUMPFAMILY_MODAL = "CLOSE_EDIT_PUMPFAMILY_MODAL";
export const CLEAR_UPDATE_PUMPFAMILY_ERROR = "CLEAR_UPDATE_PUMPFAMILY_ERROR";
export const UPDATE_PUMPFAMILY_PENDING = "UPDATE_PUMPFAMILY_PENDING";
export const UPDATE_PUMPFAMILY_SUCCESS = "UPDATE_PUMPFAMILY_SUCCESS";
export const UPDATE_PUMPFAMILY_ERROR = "UPDATE_PUMPFAMILY_ERROR";
export const CREATE_SUBTYPE_ERROR = "CREATE_SUBTYPE_ERROR";
export const CREATE_SUBTYPE_PENDING = "CREATE_SUBTYPE_PENDING";
export const CLEAR_SUBTYPE_ERROR = "CLEAR_SUBTYPE_ERROR";
export const CREATE_SUBTYPE_SUCCESS = "CREATE_SUBTYPE_SUCCESS";
export const CLEAR_UPDATE_SUBTYPE_ERROR = "CLEAR_UPDATE_SUBTYPE_ERROR";
export const UPDATE_SUBTYPE_PENDING = "UPDATE_SUBTYPE_PENDING";
export const UPDATE_SUBTYPE_SUCCESS = "UPDATE_SUBTYPE_SUCCESS";
export const CLOSE_EDIT_SUBTYPE_MODAL = "CLOSE_EDIT_SUBTYPE_MODAL";
export const UPDATE_SUBTYPE_ERROR = "UPDATE_SUBTYPE_ERROR";
export const OPEN_EDIT_SUBTYPE_MODAL = "OPEN_EDIT_SUBTYPE_MODAL";
export const SET_SUBTYPE_DETAILS = "SET_SUBTYPE_DETAILS";
export const SET_ADMIN_PUMPDATA_PENDING = "SET_ADMIN_PUMPDATA_PENDING";
export const SET_ADMIN_PUMPDATA_LIST = "SET_ADMIN_PUMPDATA_LIST";
export const SET_ACTIVE_PUMP_DATA = "SET_ACTIVE_PUMP_DATA";
export const OPEN_CREATE_PUMPS_MODAL = "OPEN_CREATE_PUMPS_MODAL";
export const CLOSE_CREATE_PUMPS_MODAL = "CLOSE_CREATE_PUMPS_MODAL";
export const CREATE_PUMP_ERROR = "CREATE_PUMP_ERROR";
export const CREATE_PUMP_PENDING = "CREATE_PUMP_PENDING";
export const CLEAR_PUMP_ERROR = "CLEAR_PUMP_ERROR";
export const CREATE_PUMP_SUCCESS = "CREATE_PUMP_SUCCESS";
export const CLOSE_CREATE_PUMP_MODAL = "CLOSE_CREATE_PUMP_MODAL";
export const CLEAR_UPDATE_PUMP_ERROR = "CLEAR_UPDATE_PUMP_ERROR";
export const UPDATE_PUMP_PENDING = "UPDATE_PUMP_PENDING";
export const UPDATE_PUMP_SUCCESS = "UPDATE_PUMP_SUCCESS";
export const CLOSE_EDIT_PUMP_MODAL = "CLOSE_EDIT_PUMP_MODAL";
export const UPDATE_PUMP_ERROR = "UPDATE_PUMP_ERROR";
export const OPEN_EDIT_PUMP_MODAL = "OPEN_EDIT_PUMP_MODAL";
export const SET_PUMP_DETAILS = "SET_PUMP_DETAILS";
export const SET_PUMP_EDITDATA_PENDING = "SET_PUMP_EDITDATA_PENDING";
export const SET_PUMP_EDITDATA_LIST = "SET_PUMP_EDITDATA_LIST";
export const SET_SUBTYPE_AVAILABILITY_PENDING = "SET_SUBTYPE_AVAILABILITY_PENDING";
export const SET_SUBTYPE_AVAILABLE_LIST = "SET_SUBTYPE_AVAILABLE_LIST";
export const SET_DIAMSPEED_PENDING = "SET_DIAMSPEED_PENDING";
export const SET_DIAMSPEED_LIST = "SET_DIAMSPEED_LIST";
export const SET_CONSTANTS_LIST_PENDING = "SET_CONSTANTS_LIST_PENDING";
export const SET_CONSTANTS_LIST = "SET_CONSTANTS_LIST";
export const OPEN_CREATE_SUBTYPEAVAIL_MODAL = "OPEN_CREATE_SUBTYPEAVAIL_MODAL";
export const CLOSE_CREATE_SUBTYPEAVAIL_MODAL = "CLOSE_CREATE_SUBTYPEAVAIL_MODAL";
export const CREATE_SUBTYPEAVAIL_PENDING = "CREATE_SUBTYPEAVAIL_PENDING";
export const CLEAR_SUBTYPEAVAIL_ERROR = "CLEAR_SUBTYPEAVAIL_ERROR";
export const CREATE_SUBTYPEAVAIL_SUCCESS = "CREATE_SUBTYPEAVAIL_SUCCESS";
export const CREATE_SUBTYPEAVAIL_ERROR = "CREATE_SUBTYPEAVAIL_ERROR";
export const OPEN_EDIT_SUBTYPEAVAIL_MODAL = "OPEN_EDIT_SUBTYPEAVAIL_MODAL";
export const SET_SUBTYPEAVAIL_DETAILS = "SET_SUBTYPEAVAIL_DETAILS";
export const CLOSE_EDIT_SUBTYPEAVAIL_MODAL = "CLOSE_EDIT_SUBTYPEAVAIL_MODAL";
export const CLEAR_UPDATE_SUBTYPEAVAIL_ERROR = "CLEAR_UPDATE_SUBTYPEAVAIL_ERROR";
export const UPDATE_SUBTYPEAVAIL_PENDING = "UPDATE_SUBTYPEAVAIL_PENDING";
export const UPDATE_SUBTYPEAVAIL_SUCCESS = "UPDATE_SUBTYPEAVAIL_SUCCESS";
export const UPDATE_SUBTYPEAVAIL_ERROR = "UPDATE_SUBTYPEAVAIL_ERROR";
export const OPEN_CREATE_DIAMSPEED_MODAL = "OPEN_CREATE_DIAMSPEED_MODAL";
export const CREATE_DIAMSPEED_ERROR = "CREATE_DIAMSPEED_ERROR";
export const CREATE_DIAMSPEED_PENDING = "CREATE_DIAMSPEED_PENDING";
export const CLEAR_DIAMSPEED_ERROR = "CLEAR_DIAMSPEED_ERROR";
export const CREATE_DIAMSPEED_SUCCESS = "CREATE_DIAMSPEED_SUCCESS";
export const CLOSE_CREATE_DIAMSPEED_MODAL = "CLOSE_CREATE_DIAMSPEED_MODAL";
export const OPEN_EDIT_DIAMSPEED_MODAL = "OPEN_EDIT_DIAMSPEED_MODAL";
export const SET_DIAMSPEED_DETAILS = "SET_DIAMSPEED_DETAILS";
export const CLOSE_EDIT_DIAMSPEED_MODAL = "CLOSE_EDIT_DIAMSPEED_MODAL";
export const CLEAR_UPDATE_DIAMSPEED_ERROR = "CLEAR_UPDATE_DIAMSPEED_ERROR";
export const UPDATE_DIAMSPEED_PENDING = "UPDATE_DIAMSPEED_PENDING";
export const UPDATE_DIAMSPEED_SUCCESS = "UPDATE_DIAMSPEED_SUCCESS";
export const UPDATE_DIAMSPEED_ERROR = "UPDATE_DIAMSPEED_ERROR";
export const OPEN_EDIT_CONSTANTS_MODAL = "OPEN_EDIT_CONSTANTS_MODAL";
export const SET_CONSTANTS_DETAILS = "SET_CONSTANTS_DETAILS";
export const CLEAR_UPDATE_CONSTANTS_ERROR = "CLEAR_UPDATE_CONSTANTS_ERROR";
export const UPDATE_CONSTANTS_PENDING = "UPDATE_CONSTANTS_PENDING";
export const UPDATE_CONSTANTS_SUCCESS = "UPDATE_CONSTANTS_SUCCESS";
export const CLOSE_EDIT_CONSTANTS_MODAL = "CLOSE_EDIT_CONSTANTS_MODAL";
export const UPDATE_CONSTANTS_ERROR = "UPDATE_CONSTANTS_ERROR";
export const CLEAR_PUMP_DETAILS = "CLEAR_PUMP_DETAILS";
export const SET_VERSION_PENDING = "SET_VERSION_PENDING";
export const SET_VERSION = "SET_VERSION";
export const CLEAR_UPDATE_VERSION_ERROR = "CLEAR_UPDATE_VERSION_ERROR";
export const UPDATE_VERSION_PENDING = "UPDATE_VERSION_PENDING";
export const UPDATE_VERSION_SUCCESS = "UPDATE_VERSION_SUCCESS";
export const UPDATE_VERSION_ERROR = " UPDATE_VERSION_ERROR";
export const OPEN_CREATE_IMPTYPE_MODAL = "OPEN_CREATE_IMPTYPE_MODAL";
export const CLOSE_CREATE_IMPTYPE_MODAL = "CLOSE_CREATE_IMPTYPE_MODAL";
export const CREATE_IMPTYPE_ERROR = "CREATE_IMPTYPE_ERROR";
export const CREATE_IMPTYPE_PENDING = "CREATE_IMPTYPE_PENDING";
export const CLEAR_IMPTYPE_ERROR = "CLEAR_IMPTYPE_ERROR";
export const CREATE_IMPTYPE_SUCCESS = "CREATE_IMPTYPE_SUCCESS";
export const SET_IMPTYPE_PENDING = "SET_IMPTYPE_PENDING";
export const SET_IMPTYPE_LIST = "SET_IMPTYPE_LIST";
export const SET_IMPTYPE_DETAILS = "SET_IMPTYPE_DETAILS";
export const OPEN_EDIT_IMPTYPE_MODAL = "OPEN_EDIT_IMPTYPE_MODAL";
export const CLOSE_EDIT_IMPTYPE_MODAL = "CLOSE_EDIT_IMPTYPE_MODAL";
export const CLEAR_UPDATE_IMPTYPE_ERROR = "CLEAR_UPDATE_IMPTYPE_ERROR";
export const UPDATE_IMPTYPE_PENDING = "UPDATE_IMPTYPE_PENDING";
export const UPDATE_IMPTYPE_SUCCESS = "UPDATE_IMPTYPE_SUCCESS";
export const UPDATE_IMPTYPE_ERROR = "UPDATE_IMPTYPE_ERROR";
export const SET_IMPMAT_PENDING = "SET_IMPMAT_PENDING";
export const SET_IMPMAT_LIST = "SET_IMPMAT_LIST";
export const OPEN_CREATE_IMPMAT_MODAL = "OPEN_CREATE_IMPMAT_MODAL";
export const CLOSE_CREATE_IMPMAT_MODAL = "CLOSE_CREATE_IMPMAT_MODAL";
export const SET_IMPMAT_DETAILS = "SET_IMPMAT_DETAILS";
export const OPEN_EDIT_IMPMAT_MODAL = "OPEN_EDIT_IMPMAT_MODAL";
export const CLOSE_EDIT_IMPMAT_MODAL = "CLOSE_EDIT_IMPMAT_MODAL";
export const CREATE_IMPMAT_ERROR = "CREATE_IMPMAT_ERROR";
export const CREATE_IMPMAT_PENDING = "CREATE_IMPMAT_PENDING";
export const CLEAR_IMPMAT_ERROR = "CLEAR_IMPMAT_ERROR";
export const CREATE_IMPMAT_SUCCESS = "CREATE_IMPMAT_SUCCESS";
export const CLEAR_UPDATE_IMPMAT_ERROR = "CLEAR_UPDATE_IMPMAT_ERROR";
export const UPDATE_IMPMAT_PENDING = "UPDATE_IMPMAT_PENDING";
export const UPDATE_IMPMAT_SUCCESS = "UPDATE_IMPMAT_SUCCESS";
export const UPDATE_IMPMAT_ERROR = "UPDATE_IMPMAT_ERROR";
export const SET_PUMP_DATA_SAVE_AND_GET_PENDING_FLAG = "SET_PUMP_DATA_SAVE_AND_GET_PENDING_FLAG";
export const SAVE_PUMP_DATA_PENDING = "SAVE_PUMP_DATA_PENDING";
export const CLEAR_SAVE_PUMP_DATA_ERROR = "CLEAR_SAVE_PUMP_DATA_ERROR";
export const SET_PUMP_DATA_COPIED_FLAG = "SET_PUMP_DATA_COPIED_FLAG";
export const SAVE_PUMP_DATA_SUCCESS = "SAVE_PUMP_DATA_SUCCESS";
export const RESET_PUMP_DATA_SAVE_AND_GET_PENDING_FLAG = "RESET_PUMP_DATA_SAVE_AND_GET_PENDING_FLAG";
export const RESET_PUMP_DATA_COPIED_FLAG = "RESET_PUMP_DATA_COPIED_FLAG";
export const SAVE_PUMP_DATA_ERROR = "SAVE_PUMP_DATA_ERROR";
export const REDIRECT_EDIT = "REDIRECT_EDIT";
export const REDIRECT_EDIT_RESET = "REDIRECT_EDIT_RESET";
export const CLEAR_ACTIVE_PUMP_FAMILY = "CLEAR_ACTIVE_PUMP_FAMILY";
export const OPEN_EDIT_VERSION_MODAL = "OPEN_EDIT_VERSION_MODAL";
export const CLOSE_EDIT_VERSION_MODAL = "CLOSE_EDIT_VERSION_MODAL";
export const OPEN_ADD_FAMILY_MODAL = "OPEN_ADD_FAMILY_MODAL";
export const CLOSE_ADD_FAMILY_MODAL = "CLOSE_ADD_FAMILY_MODAL";
export const SET_MAP_DETAILS_PENDING = "SET_MAP_DETAILS_PENDING";
export const SET_MAP_DETAILS = "SET_MAP_DETAILS";
export const CREATE_MAPFAMILY_ERROR = "CREATE_MAPFAMILY_ERROR";
export const CREATE_MAPFAMILY_PENDING = "CREATE_MAPFAMILY_PENDING";
export const CLEAR_MAPFAMILY_ERROR = "CLEAR_MAPFAMILY_ERROR";
export const CREATE_MAPFAMILY_SUCCESS = "CREATE_MAPFAMILY_SUCCESS";
export const SET_MAPFAMILY_DETAILS = "SET_MAPFAMILY_DETAILS";
export const OPEN_ADD_SUBTYPE_MODAL = "OPEN_ADD_SUBTYPE_MODAL";
export const CLOSE_ADD_SUBTYPE_MODAL = "CLOSE_ADD_SUBTYPE_MODAL";
export const SET_MAP_SUBTYPE_PENDING = "SET_MAP_SUBTYPE_PENDING";
export const SET_MAP_SUBTYPE_DATA = "SET_MAP_SUBTYPE_DATA";
export const SET_MAP_SUBTYPE_DETAILS = "SET_MAP_SUBTYPE_DETAILS";
export const CREATE_MAP_SUBTYPE_ERROR = "CREATE_MAP_SUBTYPE_ERROR";
export const CREATE_MAP_SUBTYPE_PENDING = "CREATE_MAP_SUBTYPE_PENDING";
export const CLEAR_MAP_SUBTYPE_ERROR = "CLEAR_MAP_SUBTYPE_ERROR";
export const CREATE_MAP_SUBTYPE_SUCCESS = "CREATE_MAP_SUBTYPE_SUCCESS";
export const SET_MAP_PUMPS_PENDING = "SET_MAP_PUMPS_PENDING";
export const SET_MAP_PUMPS_DATA = "SET_MAP_PUMPS_DATA";
export const OPEN_ADD_PUMPS_MODAL = "OPEN_ADD_PUMPS_MODAL";
export const SET_MAP_PUMPS_DETAILS = "SET_MAP_PUMPS_DETAILS";
export const CLOSE_ADD_PUMPS_MODAL = "CLOSE_ADD_PUMPS_MODAL";
export const CREATE_MAP_PUMPS_ERROR = "CREATE_MAP_PUMPS_ERROR";
export const CREATE_MAP_PUMPS_PENDING = "CREATE_MAP_PUMPS_PENDING";
export const CLEAR_MAP_PUMPS_ERROR = "CLEAR_MAP_PUMPS_ERROR";
export const CREATE_MAP_PUMPS_SUCCESS = "CREATE_MAP_PUMPS_SUCCESS";
export const CLEAR_PUMP_EDIT_DATA = "CLEAR_PUMP_EDIT_DATA";
export const SET_ACCESS_PENDING = "SET_ACCESS_PENDING";
export const SET_ACCESS_STATUS = "SET_ACCESS_STATUS";
export const SET_LOCK_EDITING = "SET_LOCK_EDITING";
export const SET_RELEASEDB_PENDING = "SET_RELEASEDB_PENDING";
export const SET_RELEASEDB = "SET_RELEASEDB";
export const OPEN_RELEASE_MODEL = "OPEN_RELEASE_MODEL";
export const CLOSE_RELEASE_MODEL = "CLOSE_RELEASE_MODEL";
export const SET_REFRESH_LOG_PENDING = "SET_REFRESH_LOG_PENDING";
export const SET_REFRESH_LOG = "SET_REFRESH_LOG";
export const OPEN_RELEASE_COMMENTS_MODEL = "OPEN_RELEASE_COMMENTS_MODEL";
export const CLOSE_RELEASE_COMMENTS_MODEL = "CLOSE_RELEASE_COMMENTS_MODEL";
export const SET_LOCKSCREEN = "SET_LOCKSCREEN";
export const SET_LOCKVALUE_PENDING = "SET_LOCKVALUE_PENDING";
export const SET_RELEASE_BOOLEAN = "SET_RELEASE_BOOLEAN";
export const SET_RELEASE_ERROR = "SET_RELEASE_ERROR";
export const CLEAR_ACCESS_STATUS_PENDING = "CLEAR_ACCESS_STATUS_PENDING";
export const CLEAR_ACCESS_STATUS_SUCCESS = "CLEAR_ACCESS_STATUS_SUCCESS";
export const CLEAR_OVERRIDE_PENDING = "CLEAR_OVERRIDE_PENDING";
export const CLEAR_OVERRIDE_SUCCESS = "CLEAR_OVERRIDE_SUCCESS";
export const SET_LOCK_ERROR = "SET_LOCK_ERROR";
export const SET_AVAILABLE_VALUES = "SET_AVAILABLE_VALUES";

export const ECO_TYPE = [
    {
      label: "ESOB",
      value: "ESOB",
      index: "0",
    },
    {
      label: "ESCC",
      value: "ESCC",
      index: "1",
    },
    {
        label: "ESCCi",
        value: "ESCCi",
        index: "2",
      },
      {
        label: "MS-V",
        value: "MS-v",
        index: "3",
      },
  ];

  export const PUMPKIND_TYPE = [
    {
      label: "S",
      value: "S",
      index: "0",
    },
    {
      label: "M",
      value: "M",
      index: "1",
    },
    {
        label: "F",
        value: "F",
        index: "2",
      },
  ];

  export const IMP_TYPE = [
    {
      label: "CL",
      value: "CL",
      index: "0",
    },
    {
      label: "HO",
      value: "HO",
      index: "1",
    },
    {
      label: "SO",
      value: "SO",
      index: "2",
    },
    {
      label: "CH",
      value: "CH",
      index: "3",
    },
    {
      label: "SC",
      value: "SC",
      index: "4",
    },
  ];

  export const IMP_MAT = [
    {
      label: "CI",
      value: "CI",
      index: "0",
    },
    {
      label: "SS",
      value: "SS",
      index: "1",
    },
    {
      label: "CS",
      value: "CS",
      index: "2",
    },
    {
      label: "BZ",
      value: "BZ",
      index: "3",
    },
  ];

  export const GEO_LOCATION = [
    {
      label: "EMEA",
      value: "EMEA",
      index: "0",
    },
    {
      label: "AMERICAS",
      value: "AMERICAS",
      index: "1",
    },
    {
        label: "APAC",
        value: "APAC",
        index: "2",
      },
  ];

  export const APPLICATION = [
    {
      label: "Industrial",
      value: "Industrial",
      index: "0",
    },
    {
      label: "Horticulture",
      value: "Horticulture",
      index: "1",
    }
  ];