import { combineReducers } from "redux";

import settingsReducer from "./settings.reducer.js";
import themesReducer from "./themes.reducers.js";
import formReducer from "./form.reducer.js";
import loginReducer from "./login.reducer.js";
import profileReducer from "./profile.reducer.js";
import snackbarReducer from "./snackbar.reducer.js";
import userDetailsReducer from "./userDetails.reducer.js";
import userReducer from "./user.reducer.js";
import pumpDataReducer from "./pumpData.reducer.js";
import flowMeterReducer from "./flowMeter.reducer.js";
import importReducer from "./import.reducer.js";
import graphReducer from "./graph.reducer.js";
import exportReducer from "./export.reducer.js";
import qhSelectionReducer from "./qh-selection.reducer.js";
import maintenenceReducer from "./maintenence.reducer.js";
import compareScreenReducers from "./compareScreen.reducers.js";

export default combineReducers({
  settings: settingsReducer,
  theme: themesReducer,
  form: formReducer,
  login: loginReducer,
  profile: profileReducer,
  snackbar: snackbarReducer,
  userDetails: userDetailsReducer,
  user: userReducer,
  pumpData: pumpDataReducer,
  flowMeter: flowMeterReducer,
  import: importReducer,
  graph: graphReducer,
  export: exportReducer,
  qhSelection: qhSelectionReducer,
  hi3Maintenance: maintenenceReducer,
  compareScreen: compareScreenReducers
});
