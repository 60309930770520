import { SET_LS_GRAPH_DATA, SET_LS_PS_GRAPH_DATA, SET_RS_GRAPH_DATA, SET_RS_PS_GRAPH_DATA, OPEN_SELECT_PUMPS_MODALS_LS, CLOSE_SELECT_PUMP_LS_MODAL } from "../constants/compareScreen.constants"


const initialState = {
    lsGraphData: {},
    rsGraphData: {},
    lsPSGraphData: {},
    rsPSGraphData: {},
    isSelectPumpsLSModalOpen: false,
}

export default (
    State = initialState,
    action: Action
) => {
    switch (action.type) {

        case SET_LS_GRAPH_DATA: {
            return {
                ...State,
                lsGraphData: action.payload,
            }
        }

        case SET_RS_GRAPH_DATA: {
            return {
                ...State,
                rsGraphData: action.payload,
            }
        }

        case SET_LS_PS_GRAPH_DATA: {
            return {
                ...State,
                lsPSGraphData: action.payload,
            }
        }

        case SET_RS_PS_GRAPH_DATA: {
            return {
                ...State,
                rsPSGraphData: action.payload,
            }
        }
        case OPEN_SELECT_PUMPS_MODALS_LS: {
            return{
                ...State,
                isSelectPumpsLSModalOpen: true,
                splitGraphData: action.payload,
            }
        }
        case CLOSE_SELECT_PUMP_LS_MODAL: {
            return{
                ...State,
                isSelectPumpsLSModalOpen: false,
            }
        }

        default: {
            return State;
        }
    }
}