const initialState = {
  selectedDutypoints: {},
};

const formReducer = (
  localState: Object = initialState,
  action: any
): Object => {
  switch (action.type) {
    case "FORM_UPDATE":
      if (action.payload)
        return {
          ...localState,
          ...action.payload,
        };
      return initialState;

    case "SELECTED_DUTYPOINTS": {
      if (action.payload) {
        return {
          ...localState,
          selectedDutypoints: action.payload,
        }
      }
    }
    case "FORM_CLEAR":{
      return initialState;
    }
    default: {
      return localState;
    }
  }
};

export default formReducer;
