import {
  OPEN_CREATE_PUMPFAMILY_MODAL,
  CLOSE_CREATE_PUMPFAMILY_MODAL,
  OPEN_CREATE_SUBTYPE_MODAL,
  CLOSE_CREATE_SUBTYPE_MODAL,
  SET_ADMINFAMILY_PENDING,
  SET_ADMINFAMILY_LIST,
  SET_ADMINSUBTYPE_PENDING,
  SET_ADMINSUBTYPE_LIST,
  CREATE_PUMPFAMILY_ERROR,
  CREATE_PUMPFAMILY_PENDING,
  CLEAR_PUMPFAMILY_ERROR,
  CREATE_PUMPFAMILY_SUCCESS,
  SET_PUMPFAMILY_DETAILS,
  OPEN_EDIT_PUMPFAMILY_MODAL,
  CLOSE_EDIT_PUMPFAMILY_MODAL,
  CLEAR_UPDATE_PUMPFAMILY_ERROR,
  UPDATE_PUMPFAMILY_PENDING,
  UPDATE_PUMPFAMILY_SUCCESS,
  UPDATE_PUMPFAMILY_ERROR,
  CREATE_SUBTYPE_ERROR,
  CREATE_SUBTYPE_PENDING,
  CLEAR_SUBTYPE_ERROR,
  CREATE_SUBTYPE_SUCCESS,
  CLEAR_UPDATE_SUBTYPE_ERROR,
  UPDATE_SUBTYPE_PENDING,
  UPDATE_SUBTYPE_SUCCESS,
  CLOSE_EDIT_SUBTYPE_MODAL,
  UPDATE_SUBTYPE_ERROR,
  OPEN_EDIT_SUBTYPE_MODAL,
  SET_SUBTYPE_DETAILS,
  SET_ADMIN_PUMPDATA_PENDING,
  SET_ADMIN_PUMPDATA_LIST,
  SET_ACTIVE_PUMP_DATA,
  OPEN_CREATE_PUMPS_MODAL,
  CLOSE_CREATE_PUMPS_MODAL,
  CREATE_PUMP_ERROR,
  CREATE_PUMP_PENDING,
  CLEAR_PUMP_ERROR,
  CREATE_PUMP_SUCCESS,
  CLOSE_CREATE_PUMP_MODAL,
  CLEAR_UPDATE_PUMP_ERROR,
  UPDATE_PUMP_PENDING,
  UPDATE_PUMP_SUCCESS,
  CLOSE_EDIT_PUMP_MODAL,
  UPDATE_PUMP_ERROR,
  OPEN_EDIT_PUMP_MODAL,
  SET_PUMP_DETAILS,
  SET_PUMP_EDITDATA_PENDING,
  SET_PUMP_EDITDATA_LIST,
  SET_SUBTYPE_AVAILABILITY_PENDING,
  SET_SUBTYPE_AVAILABLE_LIST,
  SET_DIAMSPEED_PENDING,
  SET_DIAMSPEED_LIST,
  SET_CONSTANTS_LIST_PENDING,
  SET_CONSTANTS_LIST,
  OPEN_CREATE_SUBTYPEAVAIL_MODAL,
  CLOSE_CREATE_SUBTYPEAVAIL_MODAL,
  CREATE_SUBTYPEAVAIL_PENDING,
  CLEAR_SUBTYPEAVAIL_ERROR,
  CREATE_SUBTYPEAVAIL_SUCCESS,
  CREATE_SUBTYPEAVAIL_ERROR,
  OPEN_EDIT_SUBTYPEAVAIL_MODAL,
  SET_SUBTYPEAVAIL_DETAILS,
  CLOSE_EDIT_SUBTYPEAVAIL_MODAL,
  CLEAR_UPDATE_SUBTYPEAVAIL_ERROR,
  UPDATE_SUBTYPEAVAIL_PENDING,
  UPDATE_SUBTYPEAVAIL_SUCCESS,
  UPDATE_SUBTYPEAVAIL_ERROR,
  OPEN_CREATE_DIAMSPEED_MODAL,
  CREATE_DIAMSPEED_ERROR,
  CREATE_DIAMSPEED_PENDING,
  CLEAR_DIAMSPEED_ERROR,
  CREATE_DIAMSPEED_SUCCESS,
  CLOSE_CREATE_DIAMSPEED_MODAL,
  OPEN_EDIT_DIAMSPEED_MODAL,
  SET_DIAMSPEED_DETAILS,
  CLOSE_EDIT_DIAMSPEED_MODAL,
  CLEAR_UPDATE_DIAMSPEED_ERROR,
  UPDATE_DIAMSPEED_PENDING,
  UPDATE_DIAMSPEED_SUCCESS,
  UPDATE_DIAMSPEED_ERROR,
  OPEN_EDIT_CONSTANTS_MODAL,
  SET_CONSTANTS_DETAILS,
  CLEAR_UPDATE_CONSTANTS_ERROR,
  UPDATE_CONSTANTS_PENDING,
  UPDATE_CONSTANTS_SUCCESS,
  CLOSE_EDIT_CONSTANTS_MODAL,
  UPDATE_CONSTANTS_ERROR,
  CLEAR_PUMP_DETAILS,
  SET_VERSION_PENDING,
  SET_VERSION,
  CLEAR_UPDATE_VERSION_ERROR,
  UPDATE_VERSION_PENDING,
  UPDATE_VERSION_SUCCESS,
  UPDATE_VERSION_ERROR,
  OPEN_CREATE_IMPTYPE_MODAL,
  CLOSE_CREATE_IMPTYPE_MODAL,
  CREATE_IMPTYPE_ERROR,
  CREATE_IMPTYPE_PENDING,
  CLEAR_IMPTYPE_ERROR,
  CREATE_IMPTYPE_SUCCESS,
  SET_IMPTYPE_PENDING,
  SET_IMPTYPE_LIST,
  SET_IMPTYPE_DETAILS,
  OPEN_EDIT_IMPTYPE_MODAL,
  CLOSE_EDIT_IMPTYPE_MODAL,
  CLEAR_UPDATE_IMPTYPE_ERROR,
  UPDATE_IMPTYPE_PENDING,
  UPDATE_IMPTYPE_SUCCESS,
  UPDATE_IMPTYPE_ERROR,
  SET_IMPMAT_PENDING,
  OPEN_CREATE_IMPMAT_MODAL,
  CLOSE_CREATE_IMPMAT_MODAL,
  SET_IMPMAT_DETAILS,
  OPEN_EDIT_IMPMAT_MODAL,
  CLOSE_EDIT_IMPMAT_MODAL,
  CREATE_IMPMAT_ERROR,
  CREATE_IMPMAT_PENDING,
  CLEAR_IMPMAT_ERROR,
  CREATE_IMPMAT_SUCCESS,
  CLEAR_UPDATE_IMPMAT_ERROR,
  UPDATE_IMPMAT_PENDING,
  UPDATE_IMPMAT_SUCCESS,
  UPDATE_IMPMAT_ERROR,
  SET_IMPMAT_LIST,
  SET_PUMP_DATA_SAVE_AND_GET_PENDING_FLAG,
  SAVE_PUMP_DATA_PENDING,
  CLEAR_SAVE_PUMP_DATA_ERROR,
  SET_PUMP_DATA_COPIED_FLAG,
  SAVE_PUMP_DATA_SUCCESS,
  RESET_PUMP_DATA_SAVE_AND_GET_PENDING_FLAG,
  RESET_PUMP_DATA_COPIED_FLAG,
  SAVE_PUMP_DATA_ERROR,
  REDIRECT_EDIT,
  REDIRECT_EDIT_RESET,
  CLEAR_ACTIVE_PUMP_FAMILY,
  OPEN_EDIT_VERSION_MODAL,
  CLOSE_EDIT_VERSION_MODAL,
  OPEN_ADD_FAMILY_MODAL,
  CLOSE_ADD_FAMILY_MODAL,
  SET_MAP_DETAILS_PENDING,
  SET_MAP_DETAILS,
  CREATE_MAPFAMILY_ERROR,
  CREATE_MAPFAMILY_PENDING,
  CLEAR_MAPFAMILY_ERROR,
  CREATE_MAPFAMILY_SUCCESS,
  SET_MAPFAMILY_DETAILS,
  OPEN_ADD_SUBTYPE_MODAL,
  CLOSE_ADD_SUBTYPE_MODAL,
  SET_MAP_SUBTYPE_PENDING,
  SET_MAP_SUBTYPE_DATA,
  SET_MAP_SUBTYPE_DETAILS,
  CREATE_MAP_SUBTYPE_ERROR,
  CREATE_MAP_SUBTYPE_PENDING,
  CLEAR_MAP_SUBTYPE_ERROR,
  CREATE_MAP_SUBTYPE_SUCCESS,
  SET_MAP_PUMPS_PENDING,
  SET_MAP_PUMPS_DATA,
  OPEN_ADD_PUMPS_MODAL,
  SET_MAP_PUMPS_DETAILS,
  CLOSE_ADD_PUMPS_MODAL,
  CREATE_MAP_PUMPS_ERROR,
  CREATE_MAP_PUMPS_PENDING,
  CLEAR_MAP_PUMPS_ERROR,
  CREATE_MAP_PUMPS_SUCCESS,
  CLEAR_PUMP_EDIT_DATA,
  SET_ACCESS_PENDING,
  SET_ACCESS_STATUS,
  SET_LOCK_EDITING,
  SET_RELEASEDB_PENDING,
  SET_RELEASEDB,
  OPEN_RELEASE_MODEL,
  CLOSE_RELEASE_MODEL,
  SET_REFRESH_LOG_PENDING,
  SET_REFRESH_LOG,
  OPEN_RELEASE_COMMENTS_MODEL,
  CLOSE_RELEASE_COMMENTS_MODEL,
  SET_LOCKVALUE_PENDING,
  SET_RELEASE_BOOLEAN,
  SET_RELEASE_ERROR,
  CLEAR_ACCESS_STATUS_PENDING,
  CLEAR_ACCESS_STATUS_SUCCESS,
  CLEAR_OVERRIDE_PENDING,
  CLEAR_OVERRIDE_SUCCESS,
  SET_LOCK_ERROR,
  SET_AVAILABLE_VALUES,
} from "../constants/maintenence.constants";
import { PumpFamilyState } from "../../types/maintanence";

const initialState: MaintanenceState = {
  errorMessage: null,
  isCreatePumpFamilyModalOpen: false,
  pumpFamily: [],
  SubTypes: [],
  PumpDetails: [],
  isPending: false,
  isCreateSubTypeAvailModalOpen: false,
  isEditSubTypeAvailModalOpen: false,
  isEditPumpFamilyModalOpen: false,
  isEditSubTypeModalOpen: false,
  isCreateSubtypeModalOpen: false,
  isCreatePumpsModalOpen: false,
  isEditPumpModalOpen: false,
  isCreateDiamSpeedModalOpen: false,
  isEditDiamSpeedModalOpen: false,
  isEditConstantsModalOpen: false,
  isUpdateConstantsPending: false,
  isCreateImpTypeModalOpen: false,
  isGetImpTypePending: false,
  isEditImpTypeModalOpen: false,
  isUpdateImpTypePending: false,
  isCreateImpMatModalOpen: false,
  isPumpDataSaveAndGetPending: false,
  isSavePumpDataPending: false,
  isPumpDataCopied: false,
  isEditRedirect: false,
  isEditVersionModalOpen: false,
  activePumpFamily: "MCH",
  isGetMapdetailsPending: false,
  isGetMapSubTypePending: false,
  isGetMapPumpsPending: false,
  accessStatusPending: false,
  lockEditing: false,
  lockPending: false,
  openRelease: false,
  getReleaseLogPending: false,
  openReleaseComments: false,
  releasePending: false,
  releaseData: null,
  releaseBoolean: null,
  releaseLog: null,
  lockError: null,
};

export default (
  localState: PumpFamilyState = initialState,
  action: Action
): PumpFamilyState => {
  switch (action.type) {
    case OPEN_CREATE_PUMPFAMILY_MODAL: {
      return {
        ...localState,
        isCreatePumpFamilyModalOpen: true,
      };
    }
    case CLOSE_CREATE_PUMPFAMILY_MODAL: {
      return {
        ...localState,
        isCreatePumpFamilyModalOpen: false,
      };
    }
    case OPEN_CREATE_SUBTYPE_MODAL: {
      return {
        ...localState,
        isCreateSubtypeModalOpen: true,
      };
    }
    case CLOSE_CREATE_SUBTYPE_MODAL: {
      return {
        ...localState,
        isCreateSubtypeModalOpen: false,
      };
    }
    case SET_ADMINFAMILY_PENDING: {
      return {
        ...localState,
        isGetAdminFamilyPending: true,
      };
    }
    case SET_ADMINFAMILY_LIST: {
      const { pumpFamilyOdata } = action.payload;
      return {
        ...localState,
        isGetAdminFamilyPending: false,
        pumpFamily: pumpFamilyOdata,
      };
    }
    case SET_ADMINSUBTYPE_PENDING: {
      return {
        ...localState,
        isGetAdminSubTypePending: true,
      };
    }
    case SET_ADMINSUBTYPE_LIST: {
      const { subTypeOdata } = action.payload;
      return {
        ...localState,
        isGetAdminSubTypePending: false,
        subType: subTypeOdata,
      };
    }
    case CREATE_PUMPFAMILY_ERROR: {
      const { errorMessage } = action.payload;
      return {
        ...localState,
        errorMessage,
        isPending: false,
      };
    }
    case CREATE_PUMPFAMILY_PENDING: {
      return {
        ...localState,
        isPending: true,
      };
    }
    case CLEAR_PUMPFAMILY_ERROR: {
      return {
        ...localState,
        errorMessage: null,
      };
    }
    case CREATE_PUMPFAMILY_SUCCESS: {
      return {
        ...localState,
        isPending: false,
      };
    }
    case SET_PUMPFAMILY_DETAILS: {
      return {
        ...localState,
        pumpFamilyEdit: action.payload,
      };
    }
    case OPEN_EDIT_PUMPFAMILY_MODAL: {
      return {
        ...localState,
        isEditPumpFamilyModalOpen: true,
      };
    }
    case CLOSE_EDIT_PUMPFAMILY_MODAL: {
      return {
        ...localState,
        isEditPumpFamilyModalOpen: false,
        updatePumpFamilyErrorMessage: null,
      };
    }
    case CLEAR_UPDATE_PUMPFAMILY_ERROR: {
      return {
        ...localState,
        updatePumpFamilyErrorMessage: null,
        isUpdatePumpFamilyPending: false,
      };
    }
    case UPDATE_PUMPFAMILY_PENDING: {
      return {
        ...localState,
        isUpdatePumpFamilyPending: true,
      };
    }
    case UPDATE_PUMPFAMILY_SUCCESS: {
      return {
        ...localState,
        isUpdatePumpFamilyPending: false,
      };
    }
    case UPDATE_PUMPFAMILY_ERROR: {
      const { errorMessage } = action.payload;
      return {
        ...localState,
        updatePumpFamilyErrorMessage: errorMessage,
        isUpdatePumpFamilyPending: false,
      };
    }
    case CREATE_SUBTYPE_ERROR: {
      const { errorMessage } = action.payload;
      return {
        ...localState,
        errorMessage,
        isPending: false,
      };
    }
    case CREATE_SUBTYPE_PENDING: {
      return {
        ...localState,
        isPending: true,
      };
    }
    case CLEAR_SUBTYPE_ERROR: {
      return {
        ...localState,
        errorMessage: null,
      };
    }
    case CREATE_SUBTYPE_SUCCESS: {
      return {
        ...localState,
        isPending: false,
      };
    }
    case CLEAR_UPDATE_SUBTYPE_ERROR: {
      return {
        ...localState,
        updateSubTypeErrorMessage: null,
        isUpdateSubTypePending: false,
      };
    }
    case UPDATE_SUBTYPE_PENDING: {
      return {
        ...localState,
        isUpdateSubTypePending: true,
      };
    }
    case UPDATE_SUBTYPE_SUCCESS: {
      return {
        ...localState,
        isUpdateSubTypePending: false,
      };
    }
    case CLOSE_EDIT_SUBTYPE_MODAL: {
      return {
        ...localState,
        isEditSubTypeModalOpen: false,
        updateSubTypeErrorMessage: null,
      };
    }
    case UPDATE_SUBTYPE_ERROR: {
      const { errorMessage } = action.payload;
      return {
        ...localState,
        updateSubTypeErrorMessage: errorMessage,
        isUpdateSubTypePending: false,
      };
    }
    case OPEN_EDIT_SUBTYPE_MODAL: {
      return {
        ...localState,
        isEditSubTypeModalOpen: true,
      };
    }
    case SET_SUBTYPE_DETAILS: {
      return {
        ...localState,
        SubTypes: action.payload,
      };
    }
    case SET_ADMIN_PUMPDATA_PENDING: {
      return {
        ...localState,
        isGetAdminPumpDataPending: true,
      };
    }
    case SET_ADMIN_PUMPDATA_LIST: {
      const { pumpDataOdata } = action.payload;
      return {
        ...localState,
        isGetAdminPumpDataPending: false,
        pumpData: pumpDataOdata,
      };
    }
    case SET_ACTIVE_PUMP_DATA: {
      return {
        ...localState,
        activePumpFamily: action.payload,
      };
    }
    case OPEN_CREATE_PUMPS_MODAL: {
      return {
        ...localState,
        isCreatePumpsModalOpen: true,
      };
    }
    case CLOSE_CREATE_PUMPS_MODAL: {
      return {
        ...localState,
        isCreatePumpsModalOpen: false,
      };
    }
    case CREATE_PUMP_ERROR: {
      const { errorMessage } = action.payload;
      return {
        ...localState,
        errorMessage,
        isPending: false,
      };
    }
    case CREATE_PUMP_PENDING: {
      return {
        ...localState,
        isPending: true,
      };
    }
    case CLEAR_PUMP_ERROR: {
      return {
        ...localState,
        errorMessage: null,
        isPending: false,
      };
    }
    case CREATE_PUMP_SUCCESS: {
      return {
        ...localState,
        isPending: false,
      };
    }
    case CLOSE_CREATE_PUMP_MODAL: {
      return {
        ...localState,
        isCreatePumpModalOpen: false,
        errorMessage: null,
      };
    }
    case CLEAR_UPDATE_PUMP_ERROR: {
      return {
        ...localState,
        updatePumpErrorMessage: null,
        isUpdatePumpPending: false,
      };
    }
    case UPDATE_PUMP_PENDING: {
      return {
        ...localState,
        isUpdatePumpPending: true,
      };
    }
    case UPDATE_PUMP_SUCCESS: {
      return {
        ...localState,
        isUpdatePumpPending: false,
      };
    }
    case CLOSE_EDIT_PUMP_MODAL: {
      return {
        ...localState,
        isEditPumpModalOpen: false,
        updatePumpErrorMessage: null,
      };
    }
    case UPDATE_PUMP_ERROR: {
      const { errorMessage } = action.payload;
      return {
        ...localState,
        updatePumpErrorMessage: errorMessage,
        isUpdatePumpPending: false,
      };
    }
    case OPEN_EDIT_PUMP_MODAL: {
      return {
        ...localState,
        isEditPumpModalOpen: true,
      };
    }
    case SET_PUMP_DETAILS: {
      return {
        ...localState,
        Pump: action.payload,
      };
    }
    case SET_PUMP_EDITDATA_PENDING: {
      return {
        ...localState,
        isGetPumpEditDataPending: true,
        editPumpId: action.payload,
      };
    }
    case SET_PUMP_EDITDATA_LIST: {
      const { pumpOdata } = action.payload;
      return {
        ...localState,
        isGetPumpEditDataPending: false,
        PumpDetails: pumpOdata,
      };
    }
    case CLEAR_PUMP_EDIT_DATA:{
      return {
        ...localState,
        isGetPumpEditDataPending: false,
        PumpDetails: null,
      };
    }
    case SET_SUBTYPE_AVAILABILITY_PENDING: {
      return {
        ...localState,
        isGetSubTypeAvailabilityPending: true,
      };
    }
    case SET_SUBTYPE_AVAILABLE_LIST: {
      const { subTypeAvailableOdata } = action.payload;
      return {
        ...localState,
        isGetSubTypeAvailabilityPending: false,
        subTypeAvailableData: subTypeAvailableOdata,
      };
    }
    case SET_DIAMSPEED_PENDING: {
      return {
        ...localState,
        isGetDiamSpeedPending: true,
      };
    }
    case SET_DIAMSPEED_LIST: {
      const { diamSpeedOdata } = action.payload;
      return {
        ...localState,
        isGetDiamSpeedPending: false,
        diamSpeedData: diamSpeedOdata,
      };
    }
    case SET_CONSTANTS_LIST_PENDING: {
      return {
        ...localState,
        isGetConstantsPending: true,
      };
    }
    case SET_CONSTANTS_LIST: {
      const { constantsOdata } = action.payload;
      return {
        ...localState,
        isGetConstantsPending: false,
        constantsData: constantsOdata,
      };
    }
    case OPEN_CREATE_SUBTYPEAVAIL_MODAL: {
      return {
        ...localState,
        isCreateSubTypeAvailModalOpen: true,
      };
    }
    case CLOSE_CREATE_SUBTYPEAVAIL_MODAL: {
      return {
        ...localState,
        isCreateSubTypeAvailModalOpen: false,
        errorMessage: null,
      };
    }
    case CREATE_SUBTYPEAVAIL_PENDING: {
      return {
        ...localState,
        isPending: true,
      };
    }
    case CLEAR_SUBTYPEAVAIL_ERROR: {
      return {
        ...localState,
        errorMessage: null,
      };
    }
    case CREATE_SUBTYPEAVAIL_SUCCESS: {
      return {
        ...localState,
        isPending: false,
      };
    }
    case CREATE_SUBTYPEAVAIL_ERROR: {
      const { errorMessage } = action.payload;
      return {
        ...localState,
        errorMessage,
        isPending: false,
      };
    }
    case OPEN_EDIT_SUBTYPEAVAIL_MODAL:{
      return {
        ...localState,
        isEditSubTypeAvailModalOpen: true,
      };
    }
    case SET_SUBTYPEAVAIL_DETAILS:{
      return {
        ...localState,
        subTypeAvail: action.payload,
      };
    }
    case CLOSE_EDIT_SUBTYPEAVAIL_MODAL:{
      return {
        ...localState,
        isEditSubTypeAvailModalOpen: false,
        updateSubTypeAvailErrorMessage: null,
      };
    }
    case CLEAR_UPDATE_SUBTYPEAVAIL_ERROR: {
      return {
        ...localState,
        updateSubTypeAvailErrorMessage: null,
        isUpdateSubTypeAvailPending: false,
      };
    }
    case UPDATE_SUBTYPEAVAIL_PENDING: {
      return {
        ...localState,
        isUpdateSubTypeAvailPending: true,
      };
    }
    case UPDATE_SUBTYPEAVAIL_SUCCESS:{
      return {
        ...localState,
        isUpdateSubTypeAvailPending: false,
      };
    }
    case UPDATE_SUBTYPEAVAIL_ERROR:{
      const { errorMessage } = action.payload;
      return {
        ...localState,
        updateSubTypeAvailErrorMessage: errorMessage,
        isUpdateSubTypeAvailPending: false,
      };
    }
    case OPEN_CREATE_DIAMSPEED_MODAL:{
      return {
        ...localState,
        isCreateDiamSpeedModalOpen: true,
      };
    }
    case CREATE_DIAMSPEED_ERROR:{
      const { errorMessage } = action.payload;
      return {
        ...localState,
        errorMessage,
        isPending: false,
      };
    }
    case CREATE_DIAMSPEED_PENDING: {
      return {
        ...localState,
        isPending: true,
      };
    }
    case CLEAR_DIAMSPEED_ERROR:{
      return {
        ...localState,
        errorMessage: null,
      };
    }
    case CREATE_DIAMSPEED_SUCCESS:{
      return {
        ...localState,
        isPending: false,
      };
    }
    case CLOSE_CREATE_DIAMSPEED_MODAL:{
      return {
        ...localState,
        isCreateDiamSpeedModalOpen: false,
        errorMessage: null,
      };
    }
    case OPEN_EDIT_DIAMSPEED_MODAL:{
      return {
        ...localState,
        isEditDiamSpeedModalOpen: true,
      };
    }
    case SET_DIAMSPEED_DETAILS:{
      return {
        ...localState,
        diamSpeed: action.payload,
      };
    }
    case CLOSE_EDIT_DIAMSPEED_MODAL:{
      return {
        ...localState,
        isEditDiamSpeedModalOpen: false,
        updateDiamSpeedErrorMessage: null,
      };
    }
    case CLEAR_UPDATE_DIAMSPEED_ERROR:{
      return {
        ...localState,
        updateDiamSpeedErrorMessage: null,
        isUpdateDiamSpeedPending: false,
      };
    }
    case UPDATE_DIAMSPEED_PENDING:{
      return {
        ...localState,
        isUpdateDiamSpeedPending: true,
      };
    }
    case UPDATE_DIAMSPEED_SUCCESS:{
      return {
        ...localState,
        isUpdateDiamSpeedPending: false,
      };
    }
    case UPDATE_DIAMSPEED_ERROR:{
      const { errorMessage } = action.payload;
      return {
        ...localState,
        updateDiamSpeedErrorMessage: errorMessage,
        isUpdateDiamSpeedPending: false,
      };
    }
    case OPEN_EDIT_CONSTANTS_MODAL:{
      return {
        ...localState,
        isEditConstantsModalOpen: true,
      };
    }
    case SET_CONSTANTS_DETAILS:{
      return {
        ...localState,
        Constants: action.payload,
      };
    }
    case CLEAR_UPDATE_CONSTANTS_ERROR:{
      return {
        ...localState,
        updateConstantsErrorMessage: null,
        isUpdateConstantsPending: false,
      };
    }
    case UPDATE_CONSTANTS_PENDING:{
      return {
        ...localState,
        isUpdateConstantsPending: true,
      };
    }
    case UPDATE_CONSTANTS_SUCCESS:{
      return {
        ...localState,
        isUpdateConsatntsPending: false,
      };
    }
    case CLOSE_EDIT_CONSTANTS_MODAL:{
      return {
        ...localState,
        isEditConstantsModalOpen: false,
        updateConstantsErrorMessage: null,
      };
    }
    case UPDATE_CONSTANTS_ERROR:{
      const { errorMessage } = action.payload;
      return {
        ...localState,
        updateConstantsErrorMessage: errorMessage,
        isUpdateConstantsPending: false,
      };
    }
    case CLEAR_PUMP_DETAILS:{
      return {
          ...localState,
          PumpDetails: [],
        }
    }
    case SET_VERSION_PENDING:{
      return {
        ...localState,
        isGetVersionPending: true,
      };
    }
    case SET_VERSION:{
      const { version } = action.payload;
      return {
        ...localState,
        isGetVersionPending: false,
        version: version,
      };
    }
    case CLEAR_UPDATE_VERSION_ERROR:{
      return {
        ...localState,
        updateVersionErrorMessage: null,
        isUpdateVersionPending: false,
      };
    }
    case UPDATE_VERSION_PENDING:{
      return {
        ...localState,
        isUpdateVersionPending: true,
      };
    }
    case UPDATE_VERSION_SUCCESS:{
      return {
        ...localState,
        isUpdateVersionPending: false,
      };
    }
    case  UPDATE_VERSION_ERROR:{
      const { errorMessage } = action.payload;
      return {
        ...localState,
        updateVersionErrorMessage: errorMessage,
        isUpdateVersionPending: false,
      };
    }
    case OPEN_CREATE_IMPTYPE_MODAL: {
      return {
        ...localState,
        isCreateImpTypeModalOpen: true,
      };
    }
    case CLOSE_CREATE_IMPTYPE_MODAL:{
      return {
        ...localState,
        isCreateImpTypeModalOpen: false,
      };
    }
    case CREATE_IMPTYPE_ERROR:{
      const { errorMessage } = action.payload;
      return {
        ...localState,
        errorMessage,
        isPending: false,
      };
    }
    case CREATE_IMPTYPE_PENDING:{
      return {
        ...localState,
        isPending: true,
      };
    }
    case CLEAR_IMPTYPE_ERROR:{
      return {
        ...localState,
        errorMessage: null,
      };
    }
    case CREATE_IMPTYPE_SUCCESS:{
      return {
        ...localState,
        isPending: false,
      };
    }
    case SET_IMPTYPE_PENDING:{
      return {
        ...localState,
        isGetImpTypePending: true,
      };
    }
    case SET_IMPTYPE_LIST:{
      const { impTypeOdata } = action.payload;
      return {
        ...localState,
        isGetImpTypePending: false,
        impellerType: impTypeOdata,
      };
    }
    case SET_IMPTYPE_DETAILS:{
      return {
        ...localState,
        impellerTypeEdit: action.payload,
      };
    }
    case OPEN_EDIT_IMPTYPE_MODAL:{
      return {
        ...localState,
        isEditImpTypeModalOpen: true,
      };
    }
    case CLOSE_EDIT_IMPTYPE_MODAL:{
      return {
        ...localState,
        isEditImpTypeModalOpen: false,
        updateImpTypeErrorMessage: null,
      };
    }
    case CLEAR_UPDATE_IMPTYPE_ERROR:{
      return {
        ...localState,
        updateImpTypeErrorMessage: null,
        isUpdateImpTypePending: false,
      };
    }
    case UPDATE_IMPTYPE_PENDING:{
      return {
        ...localState,
        isUpdateImpTypePending: true,
      };
    }
    case UPDATE_IMPTYPE_SUCCESS:{
      return {
        ...localState,
        isUpdateImpTypePending: false,
      };
    }
    case UPDATE_IMPTYPE_ERROR:{
      const { errorMessage } = action.payload;
      return {
        ...localState,
        updateImpTypeErrorMessage: errorMessage,
        isUpdateImpTypePending: false,
      };
    }
    case SET_IMPMAT_PENDING:{
      return {
        ...localState,
        isGetImpMatPending: true,
      };
    }
    case OPEN_CREATE_IMPMAT_MODAL:{
      return {
        ...localState,
        isCreateImpMatModalOpen: true,
      };
    }
    case CLOSE_CREATE_IMPMAT_MODAL:{
      return {
        ...localState,
        isCreateImpMatModalOpen: false,
      };
    }
    case SET_IMPMAT_LIST:{
      const { impMatOdata } = action.payload;
      return {
        ...localState,
        isGetImpMatPending: false,
        impellerMaterial: impMatOdata,
      };
    }
    case SET_IMPMAT_DETAILS:{
      return {
        ...localState,
        impellerMatEdit: action.payload,
      };
    }
    case OPEN_EDIT_IMPMAT_MODAL:{
      return {
        ...localState,
        isEditImpMatModalOpen: true,
      };
    }
    case CLOSE_EDIT_IMPMAT_MODAL:{
      return {
        ...localState,
        isEditImpMatModalOpen: false,
        updateImpMatErrorMessage: null,
      };
    }
    case CREATE_IMPMAT_ERROR:{
      const { errorMessage } = action.payload;
      return {
        ...localState,
        errorMessage,
        isPending: false,
      };
    }
    case CREATE_IMPMAT_PENDING:{
      return {
        ...localState,
        isPending: true,
      };
    }
    case CLEAR_IMPMAT_ERROR:{
      return {
        ...localState,
        errorMessage: null,
      };
    }
    case CREATE_IMPMAT_SUCCESS:{
      return {
        ...localState,
        isPending: false,
      };
    }
    case CLEAR_UPDATE_IMPMAT_ERROR:{
      return {
        ...localState,
        updateImpMatErrorMessage: null,
        isUpdateImpMatPending: false,
      };
    }
    case UPDATE_IMPMAT_PENDING:{
      return {
        ...localState,
        isUpdateImpMatPending: true,
      };
    }
    case UPDATE_IMPMAT_SUCCESS:{
      return {
        ...localState,
        isUpdateImpMatPending: false,
      };
    }
    case UPDATE_IMPMAT_ERROR:{
      const { errorMessage } = action.payload;
      return {
        ...localState,
        updateImpMatErrorMessage: errorMessage,
        isUpdateImpMatPending: false,
      };
    }
    case SET_PUMP_DATA_SAVE_AND_GET_PENDING_FLAG: {
      return {
        ...localState,
        isPumpDataSaveAndGetPending: true,
      }
    }
    case SAVE_PUMP_DATA_PENDING: {
      return {
        ...localState,
        isSavePumpDataPending: true,
      };
    }
    case CLEAR_SAVE_PUMP_DATA_ERROR: {
      return {
        ...localState,
        errorMessage: null,
      };
    }
    case SET_PUMP_DATA_COPIED_FLAG: {
      return {
        ...localState,
        isPumpDataCopied: true,
      }
    }
    case SAVE_PUMP_DATA_SUCCESS: {
      return {
        ...localState,
        isSavePumpDataPending: false,
        isDataModified: false,
      };
    }
    case RESET_PUMP_DATA_SAVE_AND_GET_PENDING_FLAG: {
      return {
        ...localState,
        isPumpDataSaveAndGetPending: false,
      }
    }
    case RESET_PUMP_DATA_COPIED_FLAG: {
      return {
        ...localState,
        isPumpDataCopied: false,
      }
    }
    case SAVE_PUMP_DATA_ERROR: {
      const { errorMessage } = action.payload;
      return {
        ...localState,
        errorMessage,
        isSavePumpDataPending: false,
        isDataModified: false,
      };
    }
    case REDIRECT_EDIT:{
      const { id } = action.payload;
      return {
        ...localState,
        // isEditRedirect: true,
        editId: id,
      };
    }
    case REDIRECT_EDIT_RESET:{
      return {
        ...localState,
        isEditRedirect: false,
      };
    }
    case CLEAR_ACTIVE_PUMP_FAMILY: {
      return {
        ...localState,
        activePumpFamily: null,
      };
    }
    case OPEN_EDIT_VERSION_MODAL:{
      return {
        ...localState,
        isEditVersionModalOpen: true,
      };
    }
    case CLOSE_EDIT_VERSION_MODAL:{
      return {
        ...localState,
        isEditVersionModalOpen: false,
        updateVersionErrorMessage: null,
      };
    }
    case OPEN_ADD_FAMILY_MODAL:{
      const { id } = action.payload;
      return {
        ...localState,
        isAddFamilyModalOpen: true,
        mapId: id,
      };
    }
    case CLOSE_ADD_FAMILY_MODAL: {
      return {
        ...localState,
        isAddFamilyModalOpen: false,
        updateFamilyErrorMessage: null,
        errorMessage: null,
      };
    }
    case SET_MAP_DETAILS_PENDING: {
      return {
        ...localState,
        isGetMapdetailsPending: true,
      };
    }
    case SET_MAP_DETAILS: {
      const { detailsOdata } = action.payload;
      return {
        ...localState,
        isGetMapDetailsPending: false,
        addDetails: detailsOdata,
      };
    }
    case CREATE_MAPFAMILY_ERROR: {
      const { errorMessage } = action.payload;
      return {
        ...localState,
        errorMessage,
        isPending: false,
      };
    }
    case CREATE_MAPFAMILY_PENDING: {
      return {
        ...localState,
        isPending: true,
      };
    }
    case CLEAR_MAPFAMILY_ERROR: {
      return {
        ...localState,
        errorMessage: null,
      };
    }
    case CREATE_MAPFAMILY_SUCCESS: {
      return {
        ...localState,
        isPending: false,
      };
    }
    case SET_MAPFAMILY_DETAILS: {
      return {
        ...localState,
       mappedId: action.payload.id,
       mappedFamily: action.payload.family,
      };
    }
    case OPEN_ADD_SUBTYPE_MODAL: {
      const { id } = action.payload;
      return {
        ...localState,
        isAddSubTypeModalOpen: true,
        mapSubTypeId: id,
      };
    }
    case CLOSE_ADD_SUBTYPE_MODAL:{
      return {
        ...localState,
        isAddSubTypeModalOpen: false,
        updateFamilyErrorMessage: null,
        errorMessage: null,
      };
    }
    case SET_MAP_SUBTYPE_PENDING: {
      return {
        ...localState,
        isGetMapSubTypePending: true,
      };
    }
    case SET_MAP_SUBTYPE_DATA: {
      const { subtypeOdata } = action.payload;
      return {
        ...localState,
        isGetMapSubTypePending: false,
        mapSubType: subtypeOdata,
      };
    }
    case SET_MAP_SUBTYPE_DETAILS: {
      return {
        ...localState,
       mappedSubTypeId: action.payload.id,
       mappedSubTypeFamily: action.payload.family,
       mappedSubType: action.payload.subType,
      };
    }
    case CREATE_MAP_SUBTYPE_ERROR: {
      const { errorMessage } = action.payload;
      return {
        ...localState,
        errorMessage,
        isPending: false,
      };
    }
    case CREATE_MAP_SUBTYPE_PENDING:{
      return {
        ...localState,
        isPending: true,
      };
    }
    case CLEAR_MAP_SUBTYPE_ERROR: {
      return {
        ...localState,
        errorMessage: null,
      };
    }
    case CREATE_MAP_SUBTYPE_SUCCESS:{
      return {
        ...localState,
        isPending: false,
      };
    }
    case SET_MAP_PUMPS_PENDING :{
      return {
        ...localState,
        isGetMapPumpsPending: true,
      };
    }
    case SET_MAP_PUMPS_DATA: {
      const { pumpOdata } = action.payload;
      return {
        ...localState,
        isGetMapPumpsPending: false,
        mapPumps: pumpOdata,
      };
    }
    case OPEN_ADD_PUMPS_MODAL: {
      const { id } = action.payload;
      return {
        ...localState,
        isAddPumpsModalOpen: true,
        mapPumpsId: id,
      };
    }
    case SET_MAP_PUMPS_DETAILS:{
      return {
        ...localState,
       mappedPumpId: action.payload.id,
       mappedPumpFamily: action.payload.family,
       mappedPumpSubType: action.payload.subType,
       mappedPumptype: action.payload.type,
       mappedPumpimpMat: action.payload.impMat,
       mappedPumpimpType: action.payload.impType,
      };
    }
    case CLOSE_ADD_PUMPS_MODAL:{
      return {
        ...localState,
        isAddPumpsModalOpen: false,
        updatePumpErrorMessage: null,
        errorMessage: null,
      };
    }
    case CREATE_MAP_PUMPS_ERROR: {
      const { errorMessage } = action.payload;
      return {
        ...localState,
        errorMessage,
        isPending: false,
      };
    }
    case CREATE_MAP_PUMPS_PENDING: {
      return {
        ...localState,
        isPending: true,
      };
    }
    case CLEAR_MAP_PUMPS_ERROR: {
      return {
        ...localState,
        errorMessage: null,
      };
    }
    case CREATE_MAP_PUMPS_SUCCESS: {
      return {
        ...localState,
        isPending: false,
      };
    }
    case SET_ACCESS_PENDING:{
      return {
        ...localState,
        accessStatusPending: true,
      }
    }
    case SET_ACCESS_STATUS: {
      return {
        ...localState,
        accessStatus: action.payload,
      }
    }
    case SET_LOCK_EDITING: {
      return {
        ...localState,
        lockEditing: action.payload,
      }
    }
    case SET_LOCKVALUE_PENDING:{
      return {
        ...localState,
        lockPending: true,
      }
    }
    case SET_RELEASEDB_PENDING: {
      return {
        ...localState,
        releasePending: true,
      }
    }
    case SET_RELEASEDB: {
      return {
        ...localState,
        releaseData: action.payload,
      }
    }
    // case SET_LOCKSCREEN: {
    //   return {
    //     ...localState,
    //     releaseLog: action.payload,
    //   }
    // }
    case OPEN_RELEASE_MODEL: {
      return {
        ...localState,
        openRelease: true,
      }
    }
    case CLOSE_RELEASE_MODEL: {
      return {
        ...localState,
        openRelease: false,
      }
    }
    case SET_REFRESH_LOG_PENDING: {
      return {
        ...localState,
        getReleaseLogPending: true,
      };
    }
    case SET_REFRESH_LOG: {
      return {
        ...localState,
        getReleaseLogPending: false,
        releaseLog: action.payload,
      };
    }
    case OPEN_RELEASE_COMMENTS_MODEL: {
      return {
        ...localState,
        openReleaseComments: true,
      }
    }
    case CLOSE_RELEASE_COMMENTS_MODEL: {
      return {
        ...localState,
        openReleaseComments: false,
      }
    }
    case SET_RELEASE_BOOLEAN: {
      return {
        ...localState,
        releaseBoolean: action.payload,
      }
    }
    case SET_RELEASE_ERROR: {
      return {
        ...localState,
        releaseError: action.payload,
      }
    }
    case CLEAR_ACCESS_STATUS_PENDING: {
      return {
        ...localState,
        clearAccessPending: true,
      }
    }
    case CLEAR_ACCESS_STATUS_SUCCESS: {
      return {
        ...localState,
        clearAccessPending: false,
      }
    }
    case CLEAR_OVERRIDE_PENDING: {
      return {
        ...localState,
        clearOverridePending: true,
      }
    }
    case CLEAR_OVERRIDE_SUCCESS: {
      return {
        ...localState,
        clearOverridePending: false,
      }
    }
    case SET_LOCK_ERROR: {
      return {
        ...localState,
        lockError: action.payload,
      }
    }
    case SET_AVAILABLE_VALUES: {
      console.log("reducer", action.payload)
      return {
        ...localState,
        editAvailable: action.payload,
      }
    }
    default: {
      return localState;
    }
  }
};
