import { getToken } from "../../helpers/common";
import { CLOSE_CREATE_PUMPFAMILY_MODAL,
  OPEN_CREATE_PUMPFAMILY_MODAL,
  OPEN_CREATE_SUBTYPE_MODAL,
  CLOSE_CREATE_SUBTYPE_MODAL,
  SET_ADMINFAMILY_PENDING,
  SET_ADMINFAMILY_LIST,
  SET_ADMINSUBTYPE_PENDING,
  SET_ADMINSUBTYPE_LIST,
  CREATE_PUMPFAMILY_ERROR,
  CREATE_PUMPFAMILY_PENDING,
  CLEAR_PUMPFAMILY_ERROR,
  CREATE_PUMPFAMILY_SUCCESS,
  SET_PUMPFAMILY_DETAILS,
  OPEN_EDIT_PUMPFAMILY_MODAL,
  CLOSE_EDIT_PUMPFAMILY_MODAL,
  CLEAR_UPDATE_PUMPFAMILY_ERROR,
  UPDATE_PUMPFAMILY_PENDING,
  UPDATE_PUMPFAMILY_SUCCESS,
  UPDATE_PUMPFAMILY_ERROR,
  CREATE_SUBTYPE_ERROR,
  CREATE_SUBTYPE_PENDING,
  CLEAR_SUBTYPE_ERROR,
  CREATE_SUBTYPE_SUCCESS,
  CLEAR_UPDATE_SUBTYPE_ERROR,
  UPDATE_SUBTYPE_PENDING,
  UPDATE_SUBTYPE_SUCCESS,
  CLOSE_EDIT_SUBTYPE_MODAL,
  UPDATE_SUBTYPE_ERROR,
  OPEN_EDIT_SUBTYPE_MODAL,
  SET_SUBTYPE_DETAILS,
  SET_ADMIN_PUMPDATA_PENDING,
  SET_ADMIN_PUMPDATA_LIST,
  SET_ACTIVE_PUMP_DATA,
  OPEN_CREATE_PUMPS_MODAL,
  CLOSE_CREATE_PUMPS_MODAL,
  CREATE_PUMP_ERROR,
  CREATE_PUMP_PENDING,
  CLEAR_PUMP_ERROR,
  CREATE_PUMP_SUCCESS,
  CLOSE_CREATE_PUMP_MODAL,
  CLEAR_UPDATE_PUMP_ERROR,
  UPDATE_PUMP_PENDING,
  UPDATE_PUMP_SUCCESS,
  CLOSE_EDIT_PUMP_MODAL,
  UPDATE_PUMP_ERROR,
  OPEN_EDIT_PUMP_MODAL,
  SET_PUMP_DETAILS,
  SET_PUMP_EDITDATA_PENDING,
  SET_PUMP_EDITDATA_LIST,
  SET_SUBTYPE_AVAILABILITY_PENDING,
  SET_SUBTYPE_AVAILABLE_LIST,
  SET_DIAMSPEED_PENDING,
  SET_DIAMSPEED_LIST,
  SET_CONSTANTS_LIST_PENDING,
  SET_CONSTANTS_LIST,
  OPEN_CREATE_SUBTYPEAVAIL_MODAL,
  CLOSE_CREATE_SUBTYPEAVAIL_MODAL,
  CREATE_SUBTYPEAVAIL_PENDING,
  CLEAR_SUBTYPEAVAIL_ERROR,
  CREATE_SUBTYPEAVAIL_SUCCESS,
  CREATE_SUBTYPEAVAIL_ERROR,
  OPEN_EDIT_SUBTYPEAVAIL_MODAL,
  SET_SUBTYPEAVAIL_DETAILS,
  CLOSE_EDIT_SUBTYPEAVAIL_MODAL,
  CLEAR_UPDATE_SUBTYPEAVAIL_ERROR,
  UPDATE_SUBTYPEAVAIL_PENDING,
  UPDATE_SUBTYPEAVAIL_SUCCESS,
  UPDATE_SUBTYPEAVAIL_ERROR,
  OPEN_CREATE_DIAMSPEED_MODAL,
  CREATE_DIAMSPEED_ERROR,
  CREATE_DIAMSPEED_PENDING,
  CLEAR_DIAMSPEED_ERROR,
  CREATE_DIAMSPEED_SUCCESS,
  CLOSE_CREATE_DIAMSPEED_MODAL,
  OPEN_EDIT_DIAMSPEED_MODAL,
  SET_DIAMSPEED_DETAILS,
  CLOSE_EDIT_DIAMSPEED_MODAL,
  CLEAR_UPDATE_DIAMSPEED_ERROR,
  UPDATE_DIAMSPEED_PENDING,
  UPDATE_DIAMSPEED_SUCCESS,
  UPDATE_DIAMSPEED_ERROR,
  OPEN_EDIT_CONSTANTS_MODAL,
  SET_CONSTANTS_DETAILS,
  CLEAR_UPDATE_CONSTANTS_ERROR,
  UPDATE_CONSTANTS_PENDING,
  UPDATE_CONSTANTS_SUCCESS,
  CLOSE_EDIT_CONSTANTS_MODAL,
  UPDATE_CONSTANTS_ERROR,
  CLEAR_PUMP_DETAILS,
  SET_VERSION_PENDING,
  SET_VERSION,
  CLEAR_UPDATE_VERSION_ERROR,
  UPDATE_VERSION_PENDING,
  UPDATE_VERSION_SUCCESS,
  UPDATE_VERSION_ERROR,
  OPEN_CREATE_IMPTYPE_MODAL,
  CLOSE_CREATE_IMPTYPE_MODAL,
  CREATE_IMPTYPE_ERROR,
  CREATE_IMPTYPE_PENDING,
  CLEAR_IMPTYPE_ERROR,
  CREATE_IMPTYPE_SUCCESS,
  SET_IMPTYPE_PENDING,
  SET_IMPTYPE_LIST,
  SET_IMPTYPE_DETAILS,
  OPEN_EDIT_IMPTYPE_MODAL,
  CLOSE_EDIT_IMPTYPE_MODAL,
  CLEAR_UPDATE_IMPTYPE_ERROR,
  UPDATE_IMPTYPE_PENDING,
  UPDATE_IMPTYPE_SUCCESS,
  UPDATE_IMPTYPE_ERROR,
  SET_IMPMAT_PENDING,
  SET_IMPMAT_LIST,
  OPEN_CREATE_IMPMAT_MODAL,
  CLOSE_CREATE_IMPMAT_MODAL,
  SET_IMPMAT_DETAILS,
  OPEN_EDIT_IMPMAT_MODAL,
  CLOSE_EDIT_IMPMAT_MODAL,
  CREATE_IMPMAT_ERROR,
  CREATE_IMPMAT_PENDING,
  CLEAR_IMPMAT_ERROR,
  CREATE_IMPMAT_SUCCESS,
  CLEAR_UPDATE_IMPMAT_ERROR,
  UPDATE_IMPMAT_PENDING,
  UPDATE_IMPMAT_SUCCESS,
  UPDATE_IMPMAT_ERROR,
  SET_PUMP_DATA_SAVE_AND_GET_PENDING_FLAG,
  SAVE_PUMP_DATA_PENDING,
  CLEAR_SAVE_PUMP_DATA_ERROR,
  SET_PUMP_DATA_COPIED_FLAG,
  SAVE_PUMP_DATA_SUCCESS,
  RESET_PUMP_DATA_SAVE_AND_GET_PENDING_FLAG,
  RESET_PUMP_DATA_COPIED_FLAG,
  SAVE_PUMP_DATA_ERROR,
  REDIRECT_EDIT,
  REDIRECT_EDIT_RESET,
  CLEAR_ACTIVE_PUMP_FAMILY,
  OPEN_EDIT_VERSION_MODAL,
  CLOSE_EDIT_VERSION_MODAL,
  OPEN_ADD_FAMILY_MODAL,
  CLOSE_ADD_FAMILY_MODAL,
  SET_MAP_DETAILS_PENDING,
  SET_MAP_DETAILS,
  CREATE_MAPFAMILY_ERROR,
  CREATE_MAPFAMILY_PENDING,
  CLEAR_MAPFAMILY_ERROR,
  CREATE_MAPFAMILY_SUCCESS,
  SET_MAPFAMILY_DETAILS,
  OPEN_ADD_SUBTYPE_MODAL,
  CLOSE_ADD_SUBTYPE_MODAL,
  SET_MAP_SUBTYPE_PENDING,
  SET_MAP_SUBTYPE_DATA,
  SET_MAP_SUBTYPE_DETAILS,
  CREATE_MAP_SUBTYPE_ERROR,
  CREATE_MAP_SUBTYPE_PENDING,
  CLEAR_MAP_SUBTYPE_ERROR,
  CREATE_MAP_SUBTYPE_SUCCESS,
  SET_MAP_PUMPS_PENDING,
  SET_MAP_PUMPS_DATA,
  OPEN_ADD_PUMPS_MODAL,
  SET_MAP_PUMPS_DETAILS,
  CLOSE_ADD_PUMPS_MODAL,
  CREATE_MAP_PUMPS_ERROR,
  CREATE_MAP_PUMPS_PENDING,
  CLEAR_MAP_PUMPS_ERROR,
  CREATE_MAP_PUMPS_SUCCESS,
  CLEAR_PUMP_EDIT_DATA,
  SET_ACCESS_PENDING,
  SET_ACCESS_STATUS,
  SET_LOCK_EDITING,
  SET_RELEASEDB_PENDING,
  SET_RELEASEDB,
  OPEN_RELEASE_MODEL,
  CLOSE_RELEASE_MODEL,
  SET_REFRESH_LOG_PENDING,
  SET_REFRESH_LOG,
  OPEN_RELEASE_COMMENTS_MODEL,
  CLOSE_RELEASE_COMMENTS_MODEL,
  SET_LOCKSCREEN,
  SET_LOCKVALUE_PENDING,
  SET_RELEASE_BOOLEAN,
  SET_RELEASE_ERROR,
  CLEAR_ACCESS_STATUS_PENDING,
  CLEAR_ACCESS_STATUS_SUCCESS,
  CLEAR_OVERRIDE_PENDING,
  CLEAR_OVERRIDE_SUCCESS,
  SET_LOCK_ERROR,
  SET_AVAILABLE_VALUES,
 } from "../constants/maintenence.constants";
 import agent from "../../services/agent";
 import { showSnackbarStatus } from "./snackbar.actions";
 import { clearForm } from "./form.actions";

export const openCreatePumpFamilyModal = (): Action => ({
    type: OPEN_CREATE_PUMPFAMILY_MODAL,
});

export const closeCreatePumpFamilyModal = (): Action => ({
    type: CLOSE_CREATE_PUMPFAMILY_MODAL,
});

export const openCreateSubtypeModal = (): Action => ({
   type: OPEN_CREATE_SUBTYPE_MODAL,
});

export const closeCreateSubtypeModal = (): Action => ({
   type: CLOSE_CREATE_SUBTYPE_MODAL,
});

export const setAdminFamilyPending = (): Action => ({
   type: SET_ADMINFAMILY_PENDING,
 });

 export const setAdminSubTypePending = (): Action => ({
   type: SET_ADMINSUBTYPE_PENDING,
 });

 const setAdminFamilyList = (pumpFamilyOdata: any): Action => ({
   type: SET_ADMINFAMILY_LIST,
   payload: { pumpFamilyOdata },
 });

 const setAdminSubTypeList = (subTypeOdata: any): Action => ({
   type: SET_ADMINSUBTYPE_LIST,
   payload: { subTypeOdata },
 });

 export const createPumpFamilyError = (errorMessage: string): Action => ({
   type: CREATE_PUMPFAMILY_ERROR,
   payload: { errorMessage },
 });

 export const createPumpFamilyPending = (): Action => ({
   type: CREATE_PUMPFAMILY_PENDING,
 });

 export const clearCreatePumpFamilyError = (): Action => ({
   type: CLEAR_PUMPFAMILY_ERROR,
 });

 export const createPumpFamilySuccess = (): Action => ({
   type: CREATE_PUMPFAMILY_SUCCESS,
 });

 export const setPumpFamilyDetails = (PumpFamilyData: any): Action => ({
   type: SET_PUMPFAMILY_DETAILS,
   payload: PumpFamilyData,
 });

 export const openEditPumpFamilyModal = (): Action => ({
   type: OPEN_EDIT_PUMPFAMILY_MODAL,
 });

 export const closeEditPumpFamilyModal = (): Action => ({
  type: CLOSE_EDIT_PUMPFAMILY_MODAL,
});

export const clearUpdatePumpFamilyError = (): Action => ({
  type: CLEAR_UPDATE_PUMPFAMILY_ERROR,
});

export const updatePumpFamilyPending = (): Action => ({
  type: UPDATE_PUMPFAMILY_PENDING,
});

export const updatePumpFamilySuccess = (): Action => ({
  type: UPDATE_PUMPFAMILY_SUCCESS,
});

export const updatePumpFamilyError = (errorMessage): Action => ({
  type: UPDATE_PUMPFAMILY_ERROR,
  payload: { errorMessage },
});

export const createSubTypeError = (errorMessage: string): Action => ({
  type: CREATE_SUBTYPE_ERROR,
  payload: { errorMessage },
});

export const createSubTypePending = (): Action => ({
  type: CREATE_SUBTYPE_PENDING,
});

export const clearCreateSubTypeError = (): Action => ({
  type: CLEAR_SUBTYPE_ERROR,
});

export const createSubTypeSuccess = (): Action => ({
  type: CREATE_SUBTYPE_SUCCESS,
});

export const closeCreateSubTypeModal = (): Action => ({
  type: CLOSE_CREATE_SUBTYPE_MODAL,
});

export const clearUpdateSubTypeError = (): Action => ({
  type: CLEAR_UPDATE_SUBTYPE_ERROR,
});

export const updateSubTypePending = (): Action => ({
  type: UPDATE_SUBTYPE_PENDING,
});

export const updateSubTypeSuccess = (): Action => ({
  type: UPDATE_SUBTYPE_SUCCESS,
});

export const closeEditSubTypeModal = (): Action => ({
  type: CLOSE_EDIT_SUBTYPE_MODAL,
});

export const updateSubTypeError = (errorMessage): Action => ({
  type: UPDATE_SUBTYPE_ERROR,
  payload: { errorMessage },
});

export const openEditSubTypeModal = (): Action => ({
  type: OPEN_EDIT_SUBTYPE_MODAL,
});

export const setSubTypeDetails = (subTypeData: any): Action => ({
  type: SET_SUBTYPE_DETAILS,
  payload: subTypeData,
});

export const setAdminPumpDataPending = (): Action => ({
  type: SET_ADMIN_PUMPDATA_PENDING,
});

export const setAdminPumpData = (pumpDataOdata: any): Action => ({
  type: SET_ADMIN_PUMPDATA_LIST,
  payload: { pumpDataOdata },
});

export const setActivePumpData = (activePumpFamily: string): Action => ({
  type: SET_ACTIVE_PUMP_DATA,
  payload: activePumpFamily,
});

export const openCreatePumpsModal = (): Action => ({
  type: OPEN_CREATE_PUMPS_MODAL,
});

export const closeCreatePumpsModal = (): Action => ({
  type: CLOSE_CREATE_PUMPS_MODAL,
});

export const createPumpError = (errorMessage: string): Action => ({
  type: CREATE_PUMP_ERROR,
  payload: { errorMessage },
});

export const createPumpPending = (): Action => ({
  type: CREATE_PUMP_PENDING,
});

export const clearCreatePumpError = (): Action => ({
  type: CLEAR_PUMP_ERROR,
});

export const createPumpSuccess = (): Action => ({
  type: CREATE_PUMP_SUCCESS,
});

export const closeCreatePumpModal = (): Action => ({
  type: CLOSE_CREATE_PUMP_MODAL,
});

export const clearUpdatePumpError = (): Action => ({
  type: CLEAR_UPDATE_PUMP_ERROR,
});
 
export const updatePumpPending = (): Action => ({
  type: UPDATE_PUMP_PENDING,
});

export const updatePumpSuccess = (): Action => ({
  type: UPDATE_PUMP_SUCCESS,
});

export const closeEditPumpModal = (): Action => ({
  type: CLOSE_EDIT_PUMP_MODAL,
});

export const updatePumpError = (errorMessage): Action => ({
  type: UPDATE_PUMP_ERROR,
  payload: { errorMessage },
});

export const openEditPumpModal = (): Action => ({
  type: OPEN_EDIT_PUMP_MODAL,
});

export const setPumpDetails = (PumpData: any): Action => ({
  type: SET_PUMP_DETAILS,
  payload: PumpData,
});

export const setPumpEditDataPending = (pump: any): Action => ({
  type: SET_PUMP_EDITDATA_PENDING,
  payload: pump,
});

const setPumpEditData = (pumpOdata: any): Action => ({
  type: SET_PUMP_EDITDATA_LIST,
  payload: { pumpOdata },
});

const clearPumpEditData = () : Action => ({
  type: CLEAR_PUMP_EDIT_DATA,
});

export const setSubTypeAvailabilityPending = (): Action => ({
  type: SET_SUBTYPE_AVAILABILITY_PENDING,
});

const setSubTypeAvailability = (subTypeAvailableOdata: any): Action => ({
  type: SET_SUBTYPE_AVAILABLE_LIST,
  payload: { subTypeAvailableOdata },
});

export const setDiamSpeedListPending = (): Action => ({
  type: SET_DIAMSPEED_PENDING,
});

const setDiamSpeedList = (diamSpeedOdata: any): Action => ({
  type: SET_DIAMSPEED_LIST,
  payload: { diamSpeedOdata },
});

export const setConstantsListPending = (): Action => ({
  type: SET_CONSTANTS_LIST_PENDING,
});

const setConstantsList = (constantsOdata: any): Action => ({
  type: SET_CONSTANTS_LIST,
  payload: { constantsOdata },
});

export const openCreateSubTypeAvailModal = (): Action => ({
  type: OPEN_CREATE_SUBTYPEAVAIL_MODAL,
});

export const closeCreateSubTypeAvailModal = (): Action => ({
  type: CLOSE_CREATE_SUBTYPEAVAIL_MODAL,
});

export const createSubTypeAvailPending = (): Action => ({
  type: CREATE_SUBTYPEAVAIL_PENDING,
});

export const clearCreateSubTypeAvailError = (): Action => ({
  type: CLEAR_SUBTYPEAVAIL_ERROR,
});

export const createSubTypeAvailSuccess = (): Action => ({
  type: CREATE_SUBTYPEAVAIL_SUCCESS,
});

export const createSubTypeAvailError = (errorMessage: string): Action => ({
  type: CREATE_SUBTYPEAVAIL_ERROR,
  payload: { errorMessage },
});

export const openEditSubTypeAvailModal = (): Action => ({
  type: OPEN_EDIT_SUBTYPEAVAIL_MODAL,
});

export const setSubTypeAvailDetails = (subTypeAvailData: any): Action => ({
  type: SET_SUBTYPEAVAIL_DETAILS,
  payload: subTypeAvailData,
});

export const closeEditSubTypeAvailModal = (): Action => ({
  type: CLOSE_EDIT_SUBTYPEAVAIL_MODAL,
});

export const clearUpdateSubTypeAvailError = (): Action => ({
  type: CLEAR_UPDATE_SUBTYPEAVAIL_ERROR,
});

export const updateSubTypeAvailPending = (): Action => ({
  type: UPDATE_SUBTYPEAVAIL_PENDING,
});

export const updateSubTypeAvailSuccess = (): Action => ({
  type: UPDATE_SUBTYPEAVAIL_SUCCESS,
});

export const updateSubTypeAvailError = (errorMessage): Action => ({
  type: UPDATE_SUBTYPEAVAIL_ERROR,
  payload: { errorMessage },
});

export const openCreateDiamSpeedModal = (): Action => ({
  type: OPEN_CREATE_DIAMSPEED_MODAL,
});

export const createDiamSpeedError = (errorMessage: string): Action => ({
  type: CREATE_DIAMSPEED_ERROR,
  payload: { errorMessage },
});

export const createDiamSpeedPending = (): Action => ({
  type: CREATE_DIAMSPEED_PENDING,
});

export const clearCreateDiamSpeedError = (): Action => ({
  type: CLEAR_DIAMSPEED_ERROR,
});

export const createDiamSpeedSuccess = (): Action => ({
  type: CREATE_DIAMSPEED_SUCCESS,
});

export const closeCreateDiamSpeedModal = (): Action => ({
  type: CLOSE_CREATE_DIAMSPEED_MODAL,
});

export const openEditDiamSpeedModal = (): Action => ({
  type: OPEN_EDIT_DIAMSPEED_MODAL,
});

export const setDiamSpeedDetails = (diamSpeedData: any): Action => ({
  type: SET_DIAMSPEED_DETAILS,
  payload: diamSpeedData,
});

export const closeEditDiamSpeedModal = (): Action => ({
  type: CLOSE_EDIT_DIAMSPEED_MODAL,
});

export const clearUpdateDiamSpeedError = (): Action => ({
  type: CLEAR_UPDATE_DIAMSPEED_ERROR,
});

export const updateDiamSpeedPending = (): Action => ({
  type: UPDATE_DIAMSPEED_PENDING,
});

export const updateDiamSpeedSuccess = (): Action => ({
  type: UPDATE_DIAMSPEED_SUCCESS,
});

export const updateDiamSpeedError = (errorMessage): Action => ({
  type: UPDATE_DIAMSPEED_ERROR,
  payload: { errorMessage },
});

export const openEditConstantsModal = (): Action => ({
  type: OPEN_EDIT_CONSTANTS_MODAL,
});

export const setConstantsDetails = (ConstantsData: any): Action => ({
  type: SET_CONSTANTS_DETAILS,
  payload: ConstantsData,
});

export const clearUpdateConstantsError = (): Action => ({
  type: CLEAR_UPDATE_CONSTANTS_ERROR,
});

export const updateConstantsPending = (): Action => ({
  type: UPDATE_CONSTANTS_PENDING,
});

export const updateConstantsSuccess = (): Action => ({
  type: UPDATE_CONSTANTS_SUCCESS,
});

export const closeEditConstantsModal = (): Action => ({
  type: CLOSE_EDIT_CONSTANTS_MODAL,
});

export const updateConstantsError = (errorMessage): Action => ({
  type: UPDATE_CONSTANTS_ERROR,
  payload: { errorMessage },
});
export const clearPumpDetails = (): Action => ({
  type: CLEAR_PUMP_DETAILS,
});

export const setVersionPending = (): Action => ({
  type: SET_VERSION_PENDING,
});

const setVersion = (version: any): Action => ({
  type: SET_VERSION,
  payload: { version },
});

export const clearUpdateVersionError = (): Action => ({
  type: CLEAR_UPDATE_VERSION_ERROR,
});

export const updateVersionPending = (): Action => ({
  type: UPDATE_VERSION_PENDING,
});

export const updateVersionSuccess = (): Action => ({
  type: UPDATE_VERSION_SUCCESS,
});

export const updateVersionError = (errorMessage): Action => ({
  type: UPDATE_VERSION_ERROR,
  payload: { errorMessage },
});

export const openCreateImpTypeModal = (): Action => ({
  type: OPEN_CREATE_IMPTYPE_MODAL,
});

export const closeCreateImpTypeModal = (): Action => ({
  type: CLOSE_CREATE_IMPTYPE_MODAL,
});

export const createImpTypeError = (errorMessage: string): Action => ({
  type: CREATE_IMPTYPE_ERROR,
  payload: { errorMessage },
});

export const createImpTypePending = (): Action => ({
  type: CREATE_IMPTYPE_PENDING,
});

export const clearCreateImpTypeError = (): Action => ({
  type: CLEAR_IMPTYPE_ERROR,
});

export const createImpTypeSuccess = (): Action => ({
  type: CREATE_IMPTYPE_SUCCESS,
});

export const setImpTypePending = (): Action => ({
  type: SET_IMPTYPE_PENDING,
});

const setImpTypeList = (impTypeOdata: any): Action => ({
  type: SET_IMPTYPE_LIST,
  payload: { impTypeOdata },
});

export const setImpTypeDetails = (impTypeData: any): Action => ({
  type: SET_IMPTYPE_DETAILS,
  payload: impTypeData,
});

export const openEditImpTypeModal = (): Action => ({
  type: OPEN_EDIT_IMPTYPE_MODAL,
});

export const closeEditImpTypeModal = (): Action => ({
  type: CLOSE_EDIT_IMPTYPE_MODAL,
});

export const clearUpdateImpTypeError = (): Action => ({
  type: CLEAR_UPDATE_IMPTYPE_ERROR,
});

export const updateImpTypePending = (): Action => ({
  type: UPDATE_IMPTYPE_PENDING,
});

export const updateImpTypeSuccess = (): Action => ({
  type: UPDATE_IMPTYPE_SUCCESS,
});

export const updateImpTypeError = (errorMessage): Action => ({
  type: UPDATE_IMPTYPE_ERROR,
  payload: { errorMessage },
});

export const setImpMatPending = (): Action => ({
  type: SET_IMPMAT_PENDING,
});

const setImpMatList = (impMatOdata: any): Action => ({
  type: SET_IMPMAT_LIST,
  payload: { impMatOdata },
});

export const openCreateImpMatModal = (): Action => ({
  type: OPEN_CREATE_IMPMAT_MODAL,
});

export const closeCreateImpMatModal = (): Action => ({
  type: CLOSE_CREATE_IMPMAT_MODAL,
});

export const setImpMatDetails = (impMatData: any): Action => ({
  type: SET_IMPMAT_DETAILS,
  payload: impMatData,
});

export const openEditImpMatModal = (): Action => ({
  type: OPEN_EDIT_IMPMAT_MODAL,
});

export const closeEditImpMatModal = (): Action => ({
  type: CLOSE_EDIT_IMPMAT_MODAL,
});

export const createImpMatError = (errorMessage: string): Action => ({
  type: CREATE_IMPMAT_ERROR,
  payload: { errorMessage },
});

export const createImpMatPending = (): Action => ({
  type: CREATE_IMPMAT_PENDING,
});

export const clearCreateImpMatError = (): Action => ({
  type: CLEAR_IMPMAT_ERROR,
});

export const createImpMatSuccess = (): Action => ({
  type: CREATE_IMPMAT_SUCCESS,
});

export const clearUpdateImpMatError = (): Action => ({
  type: CLEAR_UPDATE_IMPMAT_ERROR,
});

export const updateImpMatPending = (): Action => ({
  type: UPDATE_IMPMAT_PENDING,
});

export const updateImpMatSuccess = (): Action => ({
  type: UPDATE_IMPMAT_SUCCESS,
});

export const updateImpMatError = (errorMessage): Action => ({
  type: UPDATE_IMPMAT_ERROR,
  payload: { errorMessage },
});

export const setPumpDataSaveAndGetPendingFlag = (): Action => ({
  type: SET_PUMP_DATA_SAVE_AND_GET_PENDING_FLAG,
});

export const savePumpDataPending = (): Action => ({
  type: SAVE_PUMP_DATA_PENDING,
});

export const clearSavePumpDataError = (): Action => ({
  type: CLEAR_SAVE_PUMP_DATA_ERROR,
});

export const setPumpDataCopiedFlag = (): Action => ({
  type: SET_PUMP_DATA_COPIED_FLAG,
});

export const savePumpDataSuccess = (): Action => ({
  type: SAVE_PUMP_DATA_SUCCESS,
});

export const resetPumpDataSaveAndGetPendingFlag = (): Action => ({
  type: RESET_PUMP_DATA_SAVE_AND_GET_PENDING_FLAG,
});

export const resetPumpDataCopiedFlag = (): Action => ({
  type: RESET_PUMP_DATA_COPIED_FLAG,
});

export const savePumpDataError = (errorMessage: string): Action => ({
  type: SAVE_PUMP_DATA_ERROR,
  payload: { errorMessage },
});

export const redirectEdit = (id: any): Action => ({
  type: REDIRECT_EDIT,
  payload: {id},
});

export const redirectEditReset = (): Action => ({
  type: REDIRECT_EDIT_RESET,
});

export const clearActivePumpFamily = (): Action => ({
  type: CLEAR_ACTIVE_PUMP_FAMILY,
});

export const openEditVersionModal = (): Action => ({
  type: OPEN_EDIT_VERSION_MODAL,
});

export const closeEditVersionModal = (): Action => ({
  type: CLOSE_EDIT_VERSION_MODAL,
});

export const openAddFamilyModal = (id: any): Action => ({
  type: OPEN_ADD_FAMILY_MODAL,
  payload: {id},
});

export const closeAddFamilyModal = (): Action => ({
  type: CLOSE_ADD_FAMILY_MODAL,
});

export const setMapDetailsPending = (): Action => ({
  type: SET_MAP_DETAILS_PENDING,
});

const setMapDetails = (detailsOdata: any): Action => ({
  type: SET_MAP_DETAILS,
  payload: { detailsOdata },
});

export const createMapFamilyError = (errorMessage: string): Action => ({
  type: CREATE_MAPFAMILY_ERROR,
  payload: { errorMessage },
});

export const createMapFamilyPending = (): Action => ({
  type: CREATE_MAPFAMILY_PENDING,
});

export const clearCreateMapFamilyError = (): Action => ({
  type: CLEAR_MAPFAMILY_ERROR,
});

export const createMapFamilySuccess = (): Action => ({
  type: CREATE_MAPFAMILY_SUCCESS,
});

export const setMapFamilyDetails = (id: any, family: any): Action => ({
  type: SET_MAPFAMILY_DETAILS,
  payload: {id, family},
});

export const openAddSubTypeModal = (id: any): Action => ({
  type: OPEN_ADD_SUBTYPE_MODAL,
  payload: {id},
});

export const closeMapSubTypeModal = (): Action => ({
  type: CLOSE_ADD_SUBTYPE_MODAL,
});

export const setMapSubTypePending = (): Action => ({
  type: SET_MAP_SUBTYPE_PENDING,
});

const setMapSubTypeData = (subtypeOdata: any): Action => ({
  type: SET_MAP_SUBTYPE_DATA,
  payload: { subtypeOdata },
});

export const setMapSubTypeDetails = (id: any, family: any, subType: any): Action => ({
  type: SET_MAP_SUBTYPE_DETAILS,
  payload: {id, family, subType},
});

export const createMapSubTypeError = (errorMessage: string): Action => ({
  type: CREATE_MAP_SUBTYPE_ERROR,
  payload: { errorMessage },
});

export const createMapSubTypePending = (): Action => ({
  type: CREATE_MAP_SUBTYPE_PENDING,
});

export const clearCreateMapSubTypeError = (): Action => ({
  type: CLEAR_MAP_SUBTYPE_ERROR,
});

export const createMapSubTypeSuccess = (): Action => ({
  type: CREATE_MAP_SUBTYPE_SUCCESS,
});

export const setMapPumpsPending = (): Action => ({
  type: SET_MAP_PUMPS_PENDING,
});

export const setAccessPending = (): Action => ({
  type: SET_ACCESS_PENDING,
});

export const setreleaseDBPending = (): Action => ({
  type: SET_RELEASEDB_PENDING,
});

export const setLockValuePending = (): Action => ({
  type: SET_LOCKVALUE_PENDING,
});

const setMapPumpsData = (pumpOdata: any): Action => ({
  type: SET_MAP_PUMPS_DATA,
  payload: { pumpOdata },
});

export const openAddPumpsModal = (id: any): Action => ({
  type: OPEN_ADD_PUMPS_MODAL,
  payload: {id},
});

export const setMapPumpsDetails = (id: any, family: any, subType: any, type: any, impMat: any, impType: any): Action => ({
  type: SET_MAP_PUMPS_DETAILS,
  payload: {id, family, subType, type, impMat, impType},
});

export const closeMapPumpsModal = (): Action => ({
  type: CLOSE_ADD_PUMPS_MODAL,
});

export const createMapPumpsError = (errorMessage: string): Action => ({
  type: CREATE_MAP_PUMPS_ERROR,
  payload: { errorMessage },
});

export const createMapPumpsPending = (): Action => ({
  type: CREATE_MAP_PUMPS_PENDING,
});

export const clearCreateMapPumpsError = (): Action => ({
  type: CLEAR_MAP_PUMPS_ERROR,
});

export const createMapPumpsSuccess = (): Action => ({
  type: CREATE_MAP_PUMPS_SUCCESS,
});

const setAccessStatus = (accessData: any): Action => ({
  type: SET_ACCESS_STATUS,
  payload: { accessData },
});

export const setLockEditing = (data): Action => ({
  type: SET_LOCK_EDITING,
  payload: { data },
});

const setLockScreen = (data: any): Action => ({
  type: SET_LOCKSCREEN,
  payload: { data },
});

const setreleaseDB = (data: any): Action => ({
  type: SET_RELEASEDB,
  payload:  data ,
});

export const openReleaseModel = (): Action => ({
  type: OPEN_RELEASE_MODEL,
});

export const closeReleaseModel = (): Action => ({
  type: CLOSE_RELEASE_MODEL,
});

export const setRefreshLogPending = (): Action => ({
  type: SET_REFRESH_LOG_PENDING,
});

const setRefreshLog = (data: any): Action => ({
  type: SET_REFRESH_LOG,
  payload: data,
});

export const openReleaseCommentsModel = (): Action => ({
  type: OPEN_RELEASE_COMMENTS_MODEL,
});

export const closeReleaseCommentsModel = (): Action => ({
  type: CLOSE_RELEASE_COMMENTS_MODEL,
});

export const setReleaseBoolean = (value): Action => ({
  type: SET_RELEASE_BOOLEAN,
  payload: { value },
});

export const setReleaseError = (data): Action => ({
  type: SET_RELEASE_ERROR,
  payload: data,
});

export const clearAccessStatusPending = (): Action => ({
  type: CLEAR_ACCESS_STATUS_PENDING,
});

export const clearAccessStatusSuccess = (): Action => ({
  type: CLEAR_ACCESS_STATUS_SUCCESS,
});

export const clearOverridePending = (): Action => ({
  type: CLEAR_OVERRIDE_PENDING,
});

export const clearOverrideSuccess = (): Action => ({
  type: CLEAR_OVERRIDE_SUCCESS,
});

export const setLockError = (value): Action => ({
  type: SET_LOCK_ERROR,
  payload: value,
});

export const setAvailableValues = (data): Action => ({
  type: SET_AVAILABLE_VALUES,
  payload: data,
});

export const getAdminFamilyList = () => (dispatch: Function) => {
   dispatch(setAdminFamilyPending());
   getToken(dispatch).then((accessToken) => {
     agent.AdminData.getAdminFamilyList(accessToken)
       .then((adminFamilyList) => {
         dispatch(setAdminFamilyList(adminFamilyList));
       })
       .catch((err) => {
         console.log("adminFamilyList server error", err);
         dispatch(
           showSnackbarStatus("Something went wrong. Please try again later")
         );
       });
   });
 };

 export const getAdminSubTypeList = () => (dispatch: Function) => {
   dispatch(setAdminSubTypePending());
   getToken(dispatch).then((accessToken) => {
     agent.AdminData.getAdminSubTypeList(accessToken)
       .then((adminSubTypeList) => {
         dispatch(setAdminSubTypeList(adminSubTypeList));
       })
       .catch((err) => {
         console.log("adminSubTypeList server error", err);
         dispatch(
           showSnackbarStatus("Something went wrong. Please try again later")
         );
       });
   });
 };

 export const getImpTypeList = () => (dispatch: Function) => {
  dispatch(setImpTypePending());
  getToken(dispatch).then((accessToken) => {
    agent.AdminData.getImpTypeList(accessToken)
      .then((impTypeList) => {
        dispatch(setImpTypeList(impTypeList));
      })
      .catch((err) => {
        console.log("Impeller Type server error", err);
        dispatch(
          showSnackbarStatus("Something went wrong. Please try again later")
        );
      });
  });
};

export const getImpMatList = () => (dispatch: Function) => {
  dispatch(setImpMatPending());
  getToken(dispatch).then((accessToken) => {
    agent.AdminData.getImpMatList(accessToken)
      .then((impMatList) => {
        dispatch(setImpMatList(impMatList));
      })
      .catch((err) => {
        console.log("Impeller Material server error", err);
        dispatch(
          showSnackbarStatus("Something went wrong. Please try again later")
        );
      });
  });
};

 export const createPumpFamily =
  (data: any) => (dispatch: Function, getState: Function) => {
    const currentState = getState();
    const { formAddPumpFamily } = currentState && currentState.form;

    if (!formAddPumpFamily) {
      dispatch(createPumpFamilyError("Please fill required details."));
      return;
    }

    dispatch(createPumpFamilyPending());
    dispatch(clearCreatePumpFamilyError());
    getToken(dispatch).then((accessToken) => {
      agent.AdminData.createPumpFamily(accessToken, data)
        .then(() => {
          dispatch(createPumpFamilySuccess());
          dispatch(clearForm());
          dispatch(closeCreatePumpFamilyModal());
          dispatch(getAdminFamilyList());
          dispatch(showSnackbarStatus("Pump Family created successfully"));
        })
        .catch((err) => {
          if (err && err.response) {
            dispatch(createPumpFamilyError(err.response.text));
            return;
          }
          console.log("Create Pump Family server error", err);
          dispatch(clearForm());
          dispatch(closeCreatePumpFamilyModal());
          dispatch(
            showSnackbarStatus("Something went wrong. Please try again later")
          );
        });
    });
  };

  export const createMapFamily =
  (data: any) => (dispatch: Function, getState: Function) => {
    const currentState = getState();
    const { formMapFamily } = currentState && currentState.form;

    if (!(formMapFamily && formMapFamily.geoLocation) || !(formMapFamily && formMapFamily.application) ) {
      dispatch(createMapFamilyError("Please fill required details."));
      return;
    }

    dispatch(createMapFamilyPending());
    dispatch(clearCreateMapFamilyError());
    getToken(dispatch).then((accessToken) => {
      agent.AdminData.createMapFamily(accessToken, formMapFamily)
        .then(() => {
          dispatch(createMapFamilySuccess());
          dispatch(clearForm());
          // dispatch(closeAddFamilyModal());
          dispatch(getMapDetails(formMapFamily.id));
          dispatch(showSnackbarStatus("Add Family created successfully"));
        })
        .catch((err) => {
          if (err && err.response) {
            dispatch(createMapFamilyError(err.response.text));
            return;
          }
          console.log("Add Family server error", err);
          dispatch(clearForm());
          dispatch(
            showSnackbarStatus("Something went wrong. Please try again later")
          );
        });
    });
  };

  export const createMapSubType =
  (data: any) => (dispatch: Function, getState: Function) => {
    const currentState = getState();
    const { formMapSubType } = currentState && currentState.form;

    if (!(formMapSubType && formMapSubType.geoLocation) || !(formMapSubType && formMapSubType.application) ) {
      dispatch(createMapSubTypeError("Please fill required details."));
      return;
    }

    dispatch(createMapSubTypePending());
    dispatch(clearCreateMapSubTypeError());
    getToken(dispatch).then((accessToken) => {
      agent.AdminData.createMapSubType(accessToken, formMapSubType)
        .then(() => {
          dispatch(createMapSubTypeSuccess());
          dispatch(clearForm());
          // dispatch(closeMapSubTypeModal());
          dispatch(getMapSubTypeDetails(formMapSubType.id));
          dispatch(showSnackbarStatus("Map SubType created successfully"));
        })
        .catch((err) => {
          if (err && err.response) {
            dispatch(createMapSubTypeError(err.response.text));
            return;
          }
          console.log("Map SubType server error", err);
          dispatch(clearForm());
          dispatch(
            showSnackbarStatus("Something went wrong. Please try again later")
          );
        });
    });
  };

  export const createMapPumps =
  (data: any) => (dispatch: Function, getState: Function) => {
    const currentState = getState();
    const { formMapPumpsData } = currentState && currentState.form;

    if (!(formMapPumpsData && formMapPumpsData.geoLocation) || !(formMapPumpsData && formMapPumpsData.application) ) {
      dispatch(createMapPumpsError("Please fill required details."));
      return;
    }

    dispatch(createMapPumpsPending());
    dispatch(clearCreateMapPumpsError());
    getToken(dispatch).then((accessToken) => {
      agent.AdminData.createMapPumps(accessToken, formMapPumpsData)
        .then(() => {
          dispatch(createMapPumpsSuccess());
          dispatch(clearForm());
          // dispatch(closeMapPumpsModal());
          dispatch(getMapPumpsDetails(formMapPumpsData.id));
          dispatch(showSnackbarStatus("Map Pumps created successfully"));
        })
        .catch((err) => {
          if (err && err.response) {
            dispatch(createMapPumpsError(err.response.text));
            return;
          }
          console.log("Map Pumps server error", err);
          dispatch(clearForm());
          dispatch(
            showSnackbarStatus("Something went wrong. Please try again later")
          );
        });
    });
  };

  export const createDiamSpeed = (data: any) => (dispatch: Function) => {
    // const currentState = getState();
    // const { formAddDiamSpeed } = currentState && currentState.form;

    // if (!formAddDiamSpeed) {
    //   dispatch(createDiamSpeedError("Please fill required details."));
    //   return;
    // }
    const family = data.family;
    const type = data.type;
    const impMat = data.impMat;
    const impType = data.impType;
    const dataToSend = {family,type,impMat,impType}

    dispatch(createDiamSpeedPending());
    dispatch(clearCreateDiamSpeedError());
    getToken(dispatch).then((accessToken) => {
      agent.AdminData.createDiamSpeed(accessToken, data)
        .then(() => {
          dispatch(createDiamSpeedSuccess());
          dispatch(clearForm());
          dispatch(closeCreateDiamSpeedModal());
          dispatch(getDiamSpeedList(dataToSend));
          dispatch(showSnackbarStatus("Diam/Speed created successfully"));
        })
        .catch((err) => {
          if (err && err.response) {
            dispatch(createDiamSpeedError(err.response.text));
            return;
          }
          console.log("Create Diam/Speed server error", err);
          dispatch(clearForm());
          dispatch(closeCreateDiamSpeedModal());
          dispatch(
            showSnackbarStatus("Something went wrong. Please try again later")
          );
        });
    });
  };

  export const createImpType =
  () => (dispatch: Function, getState: Function) => {
    const currentState = getState();
    const { formAddImpType } = currentState && currentState.form;

    if (!formAddImpType) {
      dispatch(createImpTypeError("Please fill required details."));
      return;
    }

    dispatch(createImpTypePending());
    dispatch(clearCreateImpTypeError());
    getToken(dispatch).then((accessToken) => {
      agent.AdminData.createImpType(accessToken, formAddImpType)
        .then(() => {
          dispatch(createImpTypeSuccess());
          dispatch(clearForm());
          dispatch(closeCreateImpTypeModal());
          dispatch(getImpTypeList());
          dispatch(showSnackbarStatus("Impeller Type created successfully"));
        })
        .catch((err) => {
          if (err && err.response) {
            dispatch(createImpTypeError(err.response.text));
            return;
          }
          console.log("Create Impeller Type server error", err);
          dispatch(clearForm());
          dispatch(closeCreateImpTypeModal());
          dispatch(
            showSnackbarStatus("Something went wrong. Please try again later")
          );
        });
    });
  };

  export const createImpMat =
  () => (dispatch: Function, getState: Function) => {
    const currentState = getState();
    const { formAddImpMat } = currentState && currentState.form;

    if (!formAddImpMat) {
      dispatch(createImpMatError("Please fill required details."));
      return;
    }

    dispatch(createImpMatPending());
    dispatch(clearCreateImpMatError());
    getToken(dispatch).then((accessToken) => {
      agent.AdminData.createImpMat(accessToken, formAddImpMat)
        .then(() => {
          dispatch(createImpMatSuccess());
          dispatch(clearForm());
          dispatch(closeCreateImpMatModal());
          dispatch(getImpMatList());
          dispatch(showSnackbarStatus("Impeller Material created successfully"));
        })
        .catch((err) => {
          if (err && err.response) {
            dispatch(createImpMatError(err.response.text));
            return;
          }
          console.log("Create Impeller Material server error", err);
          dispatch(clearForm());
          dispatch(closeCreateImpMatModal());
          dispatch(
            showSnackbarStatus("Something went wrong. Please try again later")
          );
        });
    });
  };


  export const removePumpFamily =
  (id: string, swal: any) => (dispatch: Function) => {
    getToken(dispatch).then((accessToken) => {
      agent.AdminData.removePumpFamily(accessToken, id)
        .then(() => {
          dispatch(getAdminFamilyList());
          dispatch(showSnackbarStatus("Pump Family deleted successfully"));
        })
        .catch((err) => {
          if (err && err.response) {
            dispatch(showSnackbarStatus(err.response.text));
            return;
          }
          console.log("removePumpFamily server error", err);
          dispatch(
            showSnackbarStatus("Something went wrong. Please try again later")
          );
        });
    });
  };

  export const removeMapFamily =
  (id: string, swal: any, mappedId: any) => (dispatch: Function,  getState: Function) => {
    getToken(dispatch).then((accessToken) => {
      agent.AdminData.removeMapFamily(accessToken, id)
        .then(() => {
          dispatch(getMapDetails(mappedId));
          dispatch(showSnackbarStatus("Mapped Family deleted successfully"));
        })
        .catch((err) => {
          if (err && err.response) {
            dispatch(showSnackbarStatus(err.response.text));
            return;
          }
          console.log("removeMapFamily server error", err);
          dispatch(
            showSnackbarStatus("Something went wrong. Please try again later")
          );
        });
    });
  };

  export const removeMapSubType =
  (id: string, swal: any, mappedId: any) => (dispatch: Function,  getState: Function) => {
    getToken(dispatch).then((accessToken) => {
      agent.AdminData.removeMapSubType(accessToken, id)
        .then(() => {
          dispatch(getMapSubTypeDetails(mappedId));
          dispatch(showSnackbarStatus("Mapped SubType deleted successfully"));
        })
        .catch((err) => {
          if (err && err.response) {
            dispatch(showSnackbarStatus(err.response.text));
            return;
          }
          console.log("removeMapSubType server error", err);
          dispatch(
            showSnackbarStatus("Something went wrong. Please try again later")
          );
        });
    });
  };

  export const removeMapPumps =
  (id: string, swal: any, mappedId: any) => (dispatch: Function,  getState: Function) => {
    getToken(dispatch).then((accessToken) => {
      agent.AdminData.removeMapPumps(accessToken, id)
        .then(() => {
          dispatch(getMapPumpsDetails(mappedId));
          dispatch(showSnackbarStatus("Mapped SubType deleted successfully"));
        })
        .catch((err) => {
          if (err && err.response) {
            dispatch(showSnackbarStatus(err.response.text));
            return;
          }
          console.log("removeMapPumps server error", err);
          dispatch(
            showSnackbarStatus("Something went wrong. Please try again later")
          );
        });
    });
  };


  export const removeImpType =
  (id: string, swal: any) => (dispatch: Function) => {
    getToken(dispatch).then((accessToken) => {
      agent.AdminData.removeImpType(accessToken, id)
        .then(() => {
          dispatch(getImpTypeList());
          dispatch(showSnackbarStatus("Impeller Type deleted successfully"));
        })
        .catch((err) => {
          if (err && err.response) {
            dispatch(showSnackbarStatus(err.response.text));
            return;
          }
          console.log("removeImpType server error", err);
          dispatch(
            showSnackbarStatus("Something went wrong. Please try again later")
          );
        });
    });
  };

  export const updatePumpFamily = (pumpFamily: any) => (dispatch: Function) => {
    dispatch(clearUpdatePumpFamilyError());
    dispatch(updatePumpFamilyPending());
    getToken(dispatch).then((accessToken) => {
      agent.AdminData.updatePumpFamily(accessToken, pumpFamily)
        .then(() => {
          dispatch(updatePumpFamilySuccess());
          dispatch(closeEditPumpFamilyModal());
          dispatch(getAdminFamilyList());
          dispatch(showSnackbarStatus("Pump Family details updated successfully"));
        })
        .catch((err) => {
          if (err && err.response) {
            dispatch(updatePumpFamilyError(err.response.text));
            return;
          }
          console.log("Update Pump Family server error", err);
          dispatch(updatePumpFamilyError(null));
          dispatch(closeEditPumpFamilyModal());
          dispatch(
            showSnackbarStatus("Something went wrong. Please try again later")
          );
        });
    });
  };

  export const updateImpType = (impType: any) => (dispatch: Function) => {
    dispatch(clearUpdateImpTypeError());
    dispatch(updateImpTypePending());
    getToken(dispatch).then((accessToken) => {
      agent.AdminData.updateImpType(accessToken, impType)
        .then(() => {
          dispatch(updateImpTypeSuccess());
          dispatch(closeEditImpTypeModal());
          dispatch(getImpTypeList());
          dispatch(showSnackbarStatus("Impeller Type details updated successfully"));
        })
        .catch((err) => {
          if (err && err.response) {
            dispatch(updateImpTypeError(err.response.text));
            return;
          }
          console.log("Update Impeller Type server error", err);
          dispatch(updateImpTypeError(null));
          dispatch(closeEditImpTypeModal());
          dispatch(
            showSnackbarStatus("Something went wrong. Please try again later")
          );
        });
    });
  };

  export const updateImpMat = (impMat: any) => (dispatch: Function) => {
    dispatch(clearUpdateImpMatError());
    dispatch(updateImpMatPending());
    getToken(dispatch).then((accessToken) => {
      agent.AdminData.updateImpMat(accessToken, impMat)
        .then(() => {
          dispatch(updateImpMatSuccess());
          dispatch(closeEditImpMatModal());
          dispatch(getImpMatList());
          dispatch(showSnackbarStatus("Impeller Material details updated successfully"));
        })
        .catch((err) => {
          if (err && err.response) {
            dispatch(updateImpMatError(err.response.text));
            return;
          }
          console.log("Update Impeller Material server error", err);
          dispatch(updateImpMatError(null));
          dispatch(closeEditImpMatModal());
          dispatch(
            showSnackbarStatus("Something went wrong. Please try again later")
          );
        });
    });
  };

  export const updateConstants = (constants: any) => (dispatch: Function) => {
    dispatch(clearUpdateConstantsError());
    dispatch(updateConstantsPending());
    getToken(dispatch).then((accessToken) => {
      agent.AdminData.updateConstants(accessToken, constants)
        .then(() => {
          dispatch(updateConstantsSuccess());
          dispatch(closeEditConstantsModal());
          dispatch(getConstantsList(constants.family));
          dispatch(showSnackbarStatus("Constants details updated successfully"));
        })
        .catch((err) => {
          if (err && err.response) {
            dispatch(updateConstantsError(err.response.text));
            return;
          }
          console.log("Update Constants server error", err);
          dispatch(updateConstantsError(null));
          dispatch(closeEditConstantsModal());
          dispatch(
            showSnackbarStatus("Something went wrong. Please try again later")
          );
        });
    });
  };

  export const updateSubTypeAvail = (subTypeAvail: any) => (dispatch: Function) => {
    dispatch(clearUpdateSubTypeAvailError());
    dispatch(updateSubTypeAvailPending());
    getToken(dispatch).then((accessToken) => {
      agent.AdminData.updateSubTypeAvail(accessToken, subTypeAvail)
        .then(() => {
          dispatch(updateSubTypeAvailSuccess());
          dispatch(closeEditSubTypeAvailModal());
          dispatch(getSubTypeAvailability(subTypeAvail));
          dispatch(showSnackbarStatus("SubType Availability details updated successfully"));
        })
        .catch((err) => {
          if (err && err.response) {
            dispatch(updateSubTypeAvailError(err.response.text));
            return;
          }
          console.log("Update SubType Availability server error", err);
          dispatch(updateSubTypeAvailError(null));
          dispatch(closeEditSubTypeAvailModal());
          dispatch(
            showSnackbarStatus("Something went wrong. Please try again later")
          );
        });
    });
  };

  export const updateDiamSpeed = (diamSpeed: any) => (dispatch: Function) => {
    dispatch(clearUpdateDiamSpeedError());
    dispatch(updateDiamSpeedPending());
    getToken(dispatch).then((accessToken) => {
      agent.AdminData.updateDiamSpeed(accessToken, diamSpeed)
        .then(() => {
          dispatch(updateDiamSpeedSuccess());
          dispatch(closeEditDiamSpeedModal());
          dispatch(getDiamSpeedList(diamSpeed));
          dispatch(showSnackbarStatus("Diam/Speed details updated successfully"));
        })
        .catch((err) => {
          if (err && err.response) {
            dispatch(updateDiamSpeedError(err.response.text));
            return;
          }
          console.log("Update Diam/Speed server error", err);
          dispatch(updateDiamSpeedError(null));
          dispatch(closeEditDiamSpeedModal());
          dispatch(
            showSnackbarStatus("Something went wrong. Please try again later")
          );
        });
    });
  };

  export const createSubType =
  (data) => (dispatch: Function, getState: Function) => {
    const currentState = getState();
    const { formAddSubType } = currentState && currentState.form;

    if (!formAddSubType) {
      dispatch(createSubTypeError("Please fill required details."));
      return;
    }

    dispatch(createSubTypePending());
    dispatch(clearCreateSubTypeError());
    getToken(dispatch).then((accessToken) => {
      agent.AdminData.createSubType(accessToken, data)
        .then(() => {
          dispatch(createSubTypeSuccess());
          dispatch(clearForm());
          dispatch(closeCreateSubTypeModal());
          dispatch(getAdminSubTypeList());
          dispatch(showSnackbarStatus("SubType created successfully"));
        })
        .catch((err) => {
          if (err && err.response) {
            dispatch(createSubTypeError(err.response.text));
            return;
          }
          console.log("Create SubType server error", err);
          dispatch(clearForm());
          dispatch(closeCreateSubTypeModal());
          dispatch(
            showSnackbarStatus("Something went wrong. Please try again later")
          );
        });
    });
  };

  export const updateSubType = (SubType: any) => (dispatch: Function) => {
    dispatch(clearUpdateSubTypeError());
    dispatch(updateSubTypePending());
    getToken(dispatch).then((accessToken) => {
      agent.AdminData.updateSubType(accessToken, SubType)
        .then(() => {
          dispatch(updateSubTypeSuccess());
          dispatch(closeEditSubTypeModal());
          dispatch(getAdminSubTypeList());
          dispatch(showSnackbarStatus("SubType details updated successfully"));
        })
        .catch((err) => {
          if (err && err.response) {
            dispatch(updateSubTypeError(err.response.text));
            return;
          }
          console.log("Update SubType server error", err);
          dispatch(updateSubTypeError(null));
          dispatch(closeEditSubTypeModal());
          dispatch(
            showSnackbarStatus("Something went wrong. Please try again later")
          );
        });
    });
  };

  export const editVersion = (version: any, id: any) => (dispatch: Function) => {
    dispatch(clearUpdateVersionError());
    dispatch(updateVersionPending());
    getToken(dispatch).then((accessToken) => {
      agent.AdminData.updateVersion(accessToken, version)
        .then(() => {
          dispatch(updateVersionSuccess());
          dispatch(closeEditVersionModal());
          dispatch(getVersion());
          dispatch(showSnackbarStatus("Version updated successfully"));
        })
        .catch((err) => {
          if (err && err.response) {
            dispatch(updateVersionError(err.response.text));
            return;
          }
          console.log("Update SubType server error", err);
          dispatch(updateVersionError(null));
          dispatch(
            showSnackbarStatus("Something went wrong. Please try again later")
          );
        });
    });
  };

  export const removeSubType =
  (id: string, swal: any) => (dispatch: Function) => {
    getToken(dispatch).then((accessToken) => {
      agent.AdminData.removeSubType(accessToken, id)
        .then(() => {
          dispatch(getAdminSubTypeList());
          dispatch(showSnackbarStatus("SubType deleted successfully"));
        })
        .catch((err) => {
          if (err && err.response) {
            dispatch(showSnackbarStatus(err.response.text));
            return;
          }
          console.log("removeSubType server error", err);
          dispatch(
            showSnackbarStatus("Something went wrong. Please try again later")
          );
        });
    });
  };

  export const getAdminPumpData = (pumpFamilyData) => (dispatch: Function) => {
    dispatch(setAdminPumpDataPending());
    getToken(dispatch).then((accessToken) => {
      agent.AdminData.getAdminPumpData(accessToken, pumpFamilyData)
        .then((adminPumpData) => {
          dispatch(setAdminPumpData(adminPumpData));
          dispatch(updatePumpError(null));
        })
        .catch((err) => {
          console.log("adminPumpData server error", err);
          dispatch(
            showSnackbarStatus("Something went wrong. Please try again later")
          );
        });
    });
  };

  export const createPump =
  (data) => (dispatch: Function, getState: Function) => {
    if(!(data.type)|| !(data.impMat) || !(data.impType) || !(data.family))
    {
       dispatch(createPumpError("Please fill required details."));
    }
    else{
    dispatch(createPumpPending());
    dispatch(clearCreatePumpError());
    getToken(dispatch).then((accessToken) => {
      agent.AdminData.createPump(accessToken, data)
        .then(() => {
          dispatch(createPumpSuccess());
          dispatch(clearForm());
          dispatch(getAdminPumpData(data.family));
          dispatch(showSnackbarStatus("Pump created successfully"));
        })
        .catch((err) => {
          if (err && err.response) {
            dispatch(showSnackbarStatus(err.response.text));
            return;
          }
          console.log("Create Pump server error", err);
          dispatch(clearForm());
          dispatch(
            showSnackbarStatus("Something went wrong. Please try again later")
          );
        });
    });
  }
  };

  export const updatePump = (pump: any) => (dispatch: Function) => {
    if(!(pump.type)|| !(pump.impMat) || !(pump.impType)){
      dispatch(updatePumpError("Please Fill the Required Fields"));
    }
    else {
    dispatch(clearUpdatePumpError());
    dispatch(updatePumpPending());
    getToken(dispatch).then((accessToken) => {
      agent.AdminData.updatePump(accessToken, pump)
        .then((id) => {
          dispatch(updatePumpSuccess());
          dispatch(getPumpEditData(id.id));
          dispatch(closeEditPumpModal());
          dispatch(showSnackbarStatus("Pump details updated successfully"));
          dispatch(getAdminPumpData(encodeURIComponent(pump.family)));
        })
        .catch((err) => {
          if (err && err.response) {
            dispatch(updatePumpError(err.response.text));
            return;
          }
          console.log("Update Pump server error", err);
          dispatch(updatePumpError(null));
          dispatch(
            showSnackbarStatus("Something went wrong. Please try again later")
          );
        });
    });
  }
  };

  export const getPumpEditData = (pump) => (dispatch: Function) => {
    dispatch(setPumpEditDataPending(pump));
    getToken(dispatch).then((accessToken) => {
      agent.AdminData.getPumpEditData(accessToken, pump)
        .then((pumpData) => {
          dispatch(setPumpEditData(pumpData));
        })
        .catch((err) => {
          console.log("EditData server error", err);
          dispatch(
            showSnackbarStatus("Something went wrong. Please try again later")
          );
        });
    });
  };

  export const removePump =
  (id: string, swal: any, family: any) => (dispatch: Function) => {
    getToken(dispatch).then((accessToken) => {
      agent.AdminData.removePump(accessToken, id)
        .then(() => {
          dispatch(getAdminPumpData(family)); //need to pass parameters.
          dispatch(showSnackbarStatus("Pump deleted successfully"));
        })
        .catch((err) => {
          if (err && err.response) {
            dispatch(showSnackbarStatus(err.response.text));
            return;
          }
          console.log("removePump server error", err);
          dispatch(
            showSnackbarStatus("Something went wrong. Please try again later")
          );
        });
    });
  };

  export const getSubTypeAvailability = (data) => (dispatch: Function) => {
    dispatch(setSubTypeAvailabilityPending());
    getToken(dispatch).then((accessToken) => {
      agent.AdminData.getSubTypeAvailability(accessToken, data)
        .then((subTypeAvailableList) => {
          dispatch(setSubTypeAvailability(subTypeAvailableList));
        })
        .catch((err) => {
          console.log("SubTypeAvailability server error", err);
          dispatch(
            showSnackbarStatus("Something went wrong. Please try again later")
          );
        });
    });
  };

  export const getDiamSpeedList = (data) => (dispatch: Function) => {
    dispatch(setDiamSpeedListPending());
    getToken(dispatch).then((accessToken) => {
      agent.AdminData.getDiamSpeedList(accessToken, data)
        .then((diamSpeedList) => {
          dispatch(setDiamSpeedList(diamSpeedList));
        })
        .catch((err) => {
          console.log("DiamSpeed server error", err);
          dispatch(
            showSnackbarStatus("Something went wrong. Please try again later")
          );
        });
    });
  };

  export const getConstantsList = (family) => (dispatch: Function) => {
    dispatch(setConstantsListPending());
    getToken(dispatch).then((accessToken) => {
      agent.AdminData.getConstantsList(accessToken, family)
        .then((constantsList) => {
          dispatch(setConstantsList(constantsList));
        })
        .catch((err) => {
          console.log("Constants server error", err);
          dispatch(
            showSnackbarStatus("Something went wrong. Please try again later")
          );
        });
    });
  };

  export const createSubTypeAvail =
  (data: any) => (dispatch: Function) => {
    // const currentState = getState();
    // console.log("hit",currentState.form);
    // const { formAddSubTypeAvailability } = currentState && currentState.form;

    // if (!formAddSubTypeAvailability) {
    //   dispatch(createPumpError("Please fill required details."));
    //   return;
    // }

    const family = data.family;
    const type = data.type;
    const impMat = data.impMat;
    const impType = data.impType;
    const dataToSend = {family,type,impMat,impType}
    
    dispatch(createSubTypeAvailPending());
    dispatch(clearCreateSubTypeAvailError());
    getToken(dispatch).then((accessToken) => {
      agent.AdminData.createSubTypeAvail(accessToken, data)
        .then(() => {
          dispatch(createSubTypeAvailSuccess());
          dispatch(clearForm());
          dispatch(closeCreateSubTypeAvailModal());
          dispatch(getSubTypeAvailability(dataToSend));

          dispatch(showSnackbarStatus("SubType Availability created successfully"));
        })
        .catch((err) => {
          if (err && err.response) {
            dispatch(createSubTypeAvailError(err.response.text));
            return;
          }
          console.log("Create SubType Availability server error", err);
          dispatch(clearForm());
          dispatch(
            showSnackbarStatus("Something went wrong. Please try again later")
          );
        });
    });
  };

  export const removeSubTypeAvail =
  (id: string, swal: any, data: any) => (dispatch: Function) => {
    getToken(dispatch).then((accessToken) => {
      agent.AdminData.removeSubTypeAvail(accessToken, id)
        .then(() => {
          dispatch(getSubTypeAvailability(data)); //need to pass parameters.
          dispatch(showSnackbarStatus("SubType Availability deleted successfully"));
        })
        .catch((err) => {
          if (err && err.response) {
            dispatch(showSnackbarStatus(err.response.text));
            return;
          }
          console.log("removeSubTypeAvail server error", err);
          dispatch(
            showSnackbarStatus("Something went wrong. Please try again later")
          );
        });
    });
  };

  export const removeDiamSpeed =
  (id: string, swal: any, data: any) => (dispatch: Function) => {
    getToken(dispatch).then((accessToken) => {
      agent.AdminData.removeDiamSpeed(accessToken, id)
        .then(() => {
          dispatch(getDiamSpeedList(data)); //need to pass parameters.
          dispatch(showSnackbarStatus("Diam/Speed deleted successfully"));
        })
        .catch((err) => {
          if (err && err.response) {
            dispatch(showSnackbarStatus(err.response.text));
            return;
          }
          console.log("removeDiam/Speed server error", err);
          dispatch(
            showSnackbarStatus("Something went wrong. Please try again later")
          );
        });
    });
  };

  export const getVersion = () => (dispatch: Function) => {
    dispatch(setVersionPending());
    getToken(dispatch).then((accessToken) => {
      agent.AdminData.getVersion(accessToken)
        .then((version) => {
          dispatch(setVersion(version));
        })
        .catch((err) => {
          console.log("Version server error", err);
          dispatch(
            showSnackbarStatus("Something went wrong. Please try again later")
          );
        });
    });
  };

  export const copyPumpData = (pumpData: any) => (dispatch: Function) => {
    dispatch(setPumpDataSaveAndGetPendingFlag());  //Fullpage Loader
    dispatch(savePumpDataPending());   //to define classname
    dispatch(clearPumpEditData());
    dispatch(clearSavePumpDataError());   //to set error = null
    dispatch(setPumpDataCopiedFlag()); //set isPumpDataCopied to "true" to define some classname when the data is copied.
    getToken(dispatch).then((accessToken) => {
      agent.AdminData.copyPump(accessToken, pumpData.id)
        .then((data) => {
          dispatch(setPumpEditData(data));
          dispatch(savePumpDataSuccess());  //set isDataModified=true.
          dispatch(getAdminPumpData(pumpData.family));
          dispatch(showSnackbarStatus("Pump data cloned successfully"));
          dispatch(resetPumpDataSaveAndGetPendingFlag());
        })
        .catch((err) => {
          dispatch(resetPumpDataCopiedFlag()); //set isPumpDataCopied to "false"
          dispatch(resetPumpDataSaveAndGetPendingFlag());
          if (err && err.response) {
            dispatch(savePumpDataError(err.response.text));
            return;
          }
          console.log("Pump data cloned server error", err);
          dispatch(
            showSnackbarStatus("Something went wrong. Please try again later")
          );
        });
    });
  };

  export const getMapDetails = (id) => (dispatch: Function) => {
    dispatch(setMapDetailsPending());
    getToken(dispatch).then((accessToken) => {
      agent.AdminData.getMapDetails(accessToken, id)
        .then((details) => {
          dispatch(setMapDetails(details));
        })
        .catch((err) => {
          console.log("getMapFamilyDetails server error", err);
          dispatch(
            showSnackbarStatus("Something went wrong. Please try again later")
          );
        });
    });
  };

  export const getMapSubTypeDetails = (id) => (dispatch: Function) => {
    dispatch(setMapSubTypePending());
    getToken(dispatch).then((accessToken) => {
      agent.AdminData.getMapSubTypeDetails(accessToken, id)
        .then((details) => {
          dispatch(setMapSubTypeData(details));
        })
        .catch((err) => {
          console.log("getMapSubTypeDetails server error", err);
          dispatch(
            showSnackbarStatus("Something went wrong. Please try again later")
          );
        });
    });
  };

  export const getMapPumpsDetails = (id) => (dispatch: Function) => {
    dispatch(setMapPumpsPending());
    getToken(dispatch).then((accessToken) => {
      agent.AdminData.getMapPumpsDetails(accessToken, id)
        .then((details) => {
          dispatch(setMapPumpsData(details));
        })
        .catch((err) => {
          console.log("getMapPumpsDetails server error", err);
          dispatch(
            showSnackbarStatus("Something went wrong. Please try again later")
          );
        });
    });
  };

  
export const getAccessStatus = () => (dispatch: Function) => {
  dispatch(setAccessPending());
  getToken(dispatch).then((accessToken) => {
    agent.AdminData.getAccessStatus(accessToken)
      .then((status) => {
        dispatch(setAccessStatus(status));
      })
      .catch((err) => {
        console.log("getAccessStatus server error", err);
        dispatch(
          showSnackbarStatus("Something went wrong. Please try again later")
        );
      });
  });
};

export const setLockValue = (value) => (dispatch: Function) => {
  dispatch(setLockValuePending());
  getToken(dispatch).then((accessToken) => {
    agent.AdminData.getLockValues(accessToken, value)
      .then((logList) => {
        dispatch(setLockScreen(logList));
      })
      .catch((err) => {
        console.log("LockEditing server error", err);
        dispatch(
          setLockError(err && err.response.text)
        );
      });
  });
};

export const setReleaseValue = (value) => (dispatch: Function) => {
  dispatch(setReleaseError(null));
  dispatch(setreleaseDBPending());
  dispatch(setRefreshLog(null));
  dispatch(closeReleaseCommentsModel());
  getToken(dispatch).then((accessToken) => {
    agent.AdminData.releaseDB(accessToken, value)
      .then((data) => {
        dispatch(setReleaseBoolean(false));
        dispatch(setreleaseDB(data)); 
        dispatch(refreshReleaseLog());
        dispatch(showSnackbarStatus("Successfully Released"));
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(setReleaseError(err.response.text));
          dispatch(setReleaseBoolean(false));
          return;
        }
        console.log("releaseDB server error", err);
        dispatch(
          showSnackbarStatus("Something went wrong. Please try again later")
        );
      });
  });
};

export const refreshReleaseLog = () => (dispatch: Function) => {
  dispatch(setRefreshLogPending());
  getToken(dispatch).then((accessToken) => {
    agent.AdminData.getReleaseLog(accessToken)
      .then((data) => {
        dispatch(setRefreshLog(data));
      })
      .catch((err) => {
        console.log("Release Log Table server error", err);
        dispatch(
          showSnackbarStatus("Something went wrong. Please try again later")
        );
      });
  });
};

export const clearAccessStatus = () => (dispatch: Function) => {
  dispatch(clearAccessStatusPending());
  getToken(dispatch).then((accessToken) => {
    agent.AdminData.clearAccessStatus(accessToken)
      .then(() => {
        dispatch(clearAccessStatusSuccess());
      })
      .catch((err) => {
        console.log("Clear Access Status server error", err);
        dispatch(
          showSnackbarStatus(err && err.response.text)
        );
      });
  });
};

export const handleOverride = () => (dispatch: Function) => {
  dispatch(clearOverridePending());
  getToken(dispatch).then((accessToken) => {
    agent.AdminData.overrideEditing(accessToken)
      .then(() => {
        dispatch(clearOverrideSuccess());
        dispatch(getAccessStatus());
        dispatch(showSnackbarStatus("Editing Access Enabled"));
      })
      .catch((err) => {
        console.log("Clear Override Editing server error", err);
        dispatch(
          showSnackbarStatus(err && err.response.text)
        );
      });
  });
};